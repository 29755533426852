<template>
  <div class="sales-instructions">
    <!-- Floating btn to navigate to top of page -->
    <v-btn
      color="primary"
      id="floating-button"
      fab
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>

    <v-container class="pt-0" fluid>
      <!-- Linear loader for fetching and refreshing of data -->
      <v-progress-linear class="mt-1" v-if="loading" indeterminate></v-progress-linear>

      <!-- Sticky filter header NB: not used in lg and Up -->
      <MobileFilterTab
        class="my-1 mobile-filters"
        @refreshFilters="refreshFilters"
        @refreshDefault="refreshDefault"
        :totalProperties="totalProperties"
        type="sales"
      >
        <SearchSalesInstructions 
          @selectedSalesInstruction="setSearchedSaleInstruction"
        />
      </MobileFilterTab>

      <!-- Filters for desktop-->
      <DesktopFilters
        @refreshDefault="refreshDefault"
        @refreshFilters="refreshFilters"
        type="sales"
      >
        <!-- <v-text-field
          v-model="search"
          placeholder="Search"
          color="blue"
          @input="searchFilterMehod"
        ></v-text-field> -->
        <SearchSalesInstructions 
          @selectedSalesInstruction="setSearchedSaleInstruction"
        />
      </DesktopFilters>

      <v-row>
        <v-col class="mt-n5 pt-0" cols="12" md="12" lg="4" v-for="sale in salesProperties" :key="sale.OID">
          <!-- Reusable card / also useed for letting/sales properties -->
          <PropertyCard
            class="mt-0"
            PropertyType="sale"
            :PropertyObject="sale"
            :Price="sale.GuidePrice"
            :Beds="sale.Beds"
            :Photos="sale.Photos"
            :AddressNumber="sale.AddressNumber"
            :Address1="sale.Address1"
            :Address2="sale.Address2"
            :Address3="sale.Address3"
            :Postcode="sale.Postcode"
            :Description="sale.Description"
            :Directions="sale.Directions"
            :Coordinates="sale.Coordinates"
          />
        </v-col>
      </v-row>

      <!-- Intersecting div for infinite loading -->
        <div 
          v-if="salesProperties.length >= 9"
          class=""             
          v-intersect="onIntersect" 
        ></div>

        <div class="d-flex justify-center mt-8">
          <v-progress-circular
            v-if="isIntersecting && !completedLoading"
            indeterminate
            color="primary"
            class="o"
          ></v-progress-circular>
        </div>

    </v-container>
  </div>
</template>

<script>
// import SalesInstructionCards from '@/components/Properties/SalesInstructionCards.vue'
import PropertyCard from '@/components/Properties/PropertyCard.vue';
import MobileFilterTab from '@/components/Properties/Slots/MobileFilterTab.vue';
import DesktopFilters from '@/components/Properties/Slots/DesktopFilters.vue';
import SearchSalesInstructions from '@/components/Searches/SearchSalesInstructions.vue';

export default {
  name: 'sales-instructions',
  components: {
    // SalesInstructionCards,
    PropertyCard,
    MobileFilterTab,
    DesktopFilters,
    SearchSalesInstructions
  },
  data() {
    return {
      loading: false,
      bedNumber: [0,1,2,3,4,5,6,7,8],
      minPrice: null,
      maxPrice: null,
      minBeds: null,
      maxBeds: null,
      propertyTypes: null,
      salesProperties: '',
      offset: 0,
      count: 9,
      totalProperties: 1,
      isIntersecting: false, // when user intersects this point
      completedLoading: false, // when all properties available have been loaded
    }
  },
  created() {
    this.fetchSalesProperties()
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0)
    },
    onIntersect(entries) {
      console.log('intercepting')
      this.isIntersecting = entries[0].isIntersecting
      if(this.isIntersecting) {
        this.fetchSalesProperties()
      } 
    },
    fetchSalesProperties() {
      this.loading = true
      if(this.offset >= this.totalProperties || this.salesProperties.length >= this.totalProperties) { // if full count reached, exit
        this.completedLoading = true
        this.loading = false
        return
      }
      this.$store.dispatch('mySales', { // fetching first 9 properties
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        minBeds: this.minBeds,
        maxBeds: this.maxBeds,
        propertyTypes: this.propertyTypes,
        offset: this.offset,
        count: this.count
      })
      .then( ({data}) => {
        this.offset += 9
        this.loading = false

        this.totalProperties = data.Count
        const freshData = data.Data 
        const newArray = [...this.salesProperties, ...freshData]
        this.salesProperties = newArray

        this.$emit('searchResult', this.totalProperties)
      })
    },
    clearFields() { // simply resets all search parameters
      this.minPrice =  null,
      this.maxPrice = null,
      this.minBeds = null,
      this.maxBeds = null,
      this.propertyTypes = null,
      this.offset = 0,
      this.count = 9,
      this.totalProperties = 1
    },
    refreshFilters(filter) { // new search with filters included
      this.offset = 0,
      this.count = 9,
      this.totalProperties = 1
      this.salesProperties = ''
      this.minPrice = filter.minPrice
      this.maxPrice = filter.maxPrice
      this.minBeds = filter.minBeds
      this.maxBeds = filter.maxBeds
      this.propertyTypes = filter.propertyTypes
      this.fetchSalesProperties()
    },
    refreshDefault() { // reset all filters and re populate properties
      this.salesProperties = ''
      this.clearFields()
      this.loading = true
      this.fetchSalesProperties()
    },
    setSearchedSaleInstruction(sale) {
      const saleID = sale.OID
      this.$store.dispatch('salesInstructionsDetails', saleID)
      .then( res => {
        console.log(res.data)
        const newArray = []
        newArray.push(res.data)
        this.salesProperties = newArray
      })
    },
  },
  computed: {
    salesPrices() {
      let prices = []
      for(let i = 50000; i <= 300000; i += 10000) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 325000; i <= 500000; i += 25000) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 500000; i <= 1000000; i += 50000) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 1250000; i <= 3000000; i += 250000) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 4000000; i <= 5000000; i += 1000000) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 4000000; i <= 5000000; i += 1000000) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      return prices
    },
  },
}
</script>

<style scoped>
#floating-button {
  position: fixed !important;
  top: 80vh;
  right: 20px;
  z-index: 100;
}
.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 2;
    top: 0;
}

.mobile-filters {
  z-index: 10000000 !important;
}
</style>