<template>
  <!-- This cloud search is specific for SALES INSTRUCTIONS -->
  <v-autocomplete
    @update:search-input="isTyping = true"
    @input="chosenProperties"
    v-model="model"
    :search-input.sync="searchQuery"
    label="Search Sales Instruction"
    :items="searchResult"
    item-text="Subject"
    item-value="OID"
    :loading="loading"
    :hide-no-data="hideNoData"
    :no-data-text="noDataText"
    prepend-inner-icon="mdi-home"
    :menu-props="{ offsetY: true }"
    clearable
    return-object
    hint="At least 5 characters"
    :color="color"
    outlined
    dense
  ></v-autocomplete>
</template>

<script>
// import _ from 'lodash'
import debounce from "lodash/debounce";
import axios from "axios";

export default {
  name: "search-sales-instruction",
  props: {
    color: {
      type: String,
      default: "blue",
    },
  },
  data() {
    return {
      model: null,
      searchQuery: null,
      isTyping: false,
      searchResult: [],
      loading: false,
      hideNoData: true,
      noDataText: "",
    };
  },
  watch: {
    searchQuery: debounce(function () {
      this.isTyping = false;
    }, 1000),
    isTyping: function (value) {
      if (!value && this.searchQuery && this.searchQuery.length > 5) {
        this.searchProperty(this.searchQuery);
        this.loading = true;
      }
    },
  },
  methods: {
    chosenProperties() {
      const selectedProperty = this.model;
      this.$emit("selectedSalesInstruction", selectedProperty);
      this.model = null;
    },
    searchProperty(query) {
      axios({
        method: "GET",
        url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/search/salesinstructions`,
        params: {
          address: query,
        },
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        },
      })
        .then(({ data }) => {
          if (data.Count > 0) {
            this.searchResult = data.Data;
            this.loading = false;
            // console.log('Sales instruction search OK')
          } else {
            (this.hideNoData = false), (this.loading = false);
            this.noDataText = "No data found with this name";
            // console.log('no sales instruction with this name...')
          }
        })
        .catch(() => {
          this.hideNoData = false;
          this.loading = false;
          this.noDataText = "An error has occured in the system. Try again.";
        });
    },
  },
};
</script>
