<template>
  <div>
    <v-carousel
      v-resize="onResize"
      hide-delimiter-background
      delimiter-icon="mdi-minus"
      :height="imageHeight"
    >
      <v-carousel-item
        @click="setPropertyType"
        v-for="(item,i) in Images"
        :key="i"
        :src="item"
        reverse-transition="fade-transition"
        transition="fade-transition"
      ></v-carousel-item>
    </v-carousel>

    <!-- Full screen dialog with large image carousel -->
    <v-dialog v-model="dialog" scrollable fullscreen persistent transition="dialog-transition">
      <v-card>
        <v-carousel hide-delimiter-background delimiter-icon="mdi-minus" height="100%">
          <v-carousel-item
            v-for="(item,i) in Images"
            :key="i"
            :src="item"
            reverse-transition="fade-transition"
            transition="fade-transition"
          >
            <div class="d-flex flex-wrap justify-space-between ma-3">
              <v-btn
                :small="$vuetify.breakpoint.xsOnly"
                depressed
                color="primary"
                @click="dialog = false"
                class="text-capitalize mb-3"
              >
                <v-icon left small>mdi-arrow-left</v-icon>back
              </v-btn>
              <div
                :class="{caption: $vuetify.breakpoint.xs, 'headline': $vuetify.breakpoint.mdAndUp}"
                class="white--text font-weight-light grey darken-4 pt-3 pt-md-2 px-2 property-descritpion"
                v-if="Type === 'letting' "
              >
                <span v-if="Property.Property.Beds > 0">
                  {{Property.Property.Beds}}
                  <span v-if="Property.Property.Beds > 1">beds </span>
                  <span v-else>bed </span>
                </span>
                <span>to let, </span>
                <span>{{Property.Property.AddressNumber}} {{Property.Property.Address1}}, {{Property.Property.Postcode}} </span>
                <span>@ £{{Property.AdvertisedRent | priceFilter}} pcm</span>
              </div>
              <div
                :class="{caption: $vuetify.breakpoint.xs, 'headline': $vuetify.breakpoint.mdAndUp}"
                class="white--text font-weight-light grey darken-4 lighten-2 pt-3 pt-md-2 px-2 property-descritpion"
                v-if="Type === 'sale' "
              >
                <span v-if="Property.Beds > 0">
                  {{Property.Beds}}
                  <span v-if="Property.Beds > 1">beds </span>
                  <span v-else>bed </span>
                </span>
                <span>for sale, </span>
                <span>{{Property.AddressNumber}} {{Property.Address1}}, {{Property.Postcode}} </span>
                <span>@ £{{Property.GuidePrice | priceFilter}}</span>
              </div>
            </div>
          </v-carousel-item>
        </v-carousel>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    Images: {
      type: Array
    },
    Property: {
      type: Object
    },
    Type: {
      type: String
    }
  },
  data() {
    return {
      imageHeight: 275,
      dialog: false
    };
  },
  methods: {
    setPropertyType() {
      this.dialog = true;
      // this.$store.dispatch('CHANGE_PROPERTY_TYPE', this.Type)
      // // this.$router.push({name: 'property-images', params: {id: this.Property.OID} })
      // setTimeout( () => {
      //   let routeData = this.$router.resolve({name: 'property-images', params: {id: this.Property.OID} });
      //   window.open(routeData.href, '_blank');
      // }, 100)
    },
    onResize() {
      if (window.innerWidth < 600) {
        this.imageHeight = 275;
      } else if (window.innerWidth > 600) {
        this.imageHeight = 225;
      } 
    }
  },
  filters: {
    priceFilter(item) {
      let newValue = parseFloat(item).toLocaleString("en-GB");
      return newValue;
    }
  }
};
</script>

<style scoped>
.property-descritpion {
  opacity: 0.8 !important;
  border-radius: 5px !important;
}
</style>