<template>
<!-- This component feeds into PropertyInfoDialog with details of the lettingProperty -->
  <div>
    <!-- {{ selectedPropertyLettingTenancyDetails.AccessArrangements }} -->
    <div class="intro-text subtitle-1" v-if="selectedPropertyLettingDetails || selectedPropertyLettingTenancyDetails">

      <v-card class="my-5" outlined >
        <v-card-title class="font-weight-light">Description</v-card-title>
        <v-card-subtitle>
          <div class="mt-1 mb-0 font-weight-bold">
            <span v-if="selectedPropertyLettingDetails.Beds">{{selectedPropertyLettingDetails.Beds}} </span>
            <span v-if="selectedPropertyLettingDetails.Beds > 1">beds </span>
            <span v-else>bed </span>
            <span>TO LET </span>
            <span v-if="selectedPropertyLettingTenancyDetails.AdvertisedRent">&#64; {{ returnFormattedPrice(selectedPropertyLettingTenancyDetails.AdvertisedRent) }} </span>
            <span v-if="selectedPropertyLettingTenancyDetails.StartDate">available from {{ returnDate(selectedPropertyLettingTenancyDetails.StartDate) }}</span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <v-textarea 
            v-if="selectedPropertyLettingDetails.Description"
            solo flat
            class="font-weight-light"
            :value="selectedPropertyLettingDetails.Description"
            rows="5"
            label="Property description"
            color="blue lighten-1"
            readonly
          ></v-textarea>
        </v-card-text>
      </v-card>

      <v-card class="my-5" outlined >
        <v-card-title class="pb-0 font-weight-light">Access</v-card-title>
        <v-card-text>
          <!-- Google maps link -->
          <div class="ma-3 property-directions" v-if="selectedPropertyLettingDetails.Coordinates">
            <v-btn color="blue" outlined class="body-1"  @click="linkToMaps(selectedPropertyLettingDetails.Coordinates)">
              <v-icon left>mdi-google-maps</v-icon>
              <span class="text-capitalize">Directions</span>
            </v-btn>
          </div>
          <v-textarea
            v-if="selectedPropertyLettingDetails.AccessArrangements" 
            solo flat
            class="font-weight-light"
            :value="selectedPropertyLettingDetails.AccessArrangements"
            rows="5"
            label="Access arrangements"
            color="blue lighten-1"
            readonly
          ></v-textarea>            
        </v-card-text>
      </v-card>

      <v-card
        v-if="selectedPropertyLettingDetails || selectedPropertyLettingTenancyDetails"
        class="my-5 viewing-notes"
        outlined
      >
        <v-card-title class="font-weight-light">Rental notes</v-card-title>
        <v-card-text>
          <p v-if="selectedPropertyLettingTenancyDetails.Deposit">Deposit: £{{selectedPropertyLettingTenancyDetails.Deposit}}</p>
          <p v-if="selectedPropertyLettingTenancyDetails.SpecialConditions.length">Special conditions: </p>
            <p v-if="selectedPropertyLettingTenancyDetails.SpecialConditions.length" >
              <span v-for="condition in selectedPropertyLettingTenancyDetails.SpecialConditions" :key="condition.OID">{{condition.Condition}}</span>
            </p>
          <p v-if="selectedPropertyLettingDetails.KeyBunchNumber">Key number: {{selectedPropertyLettingDetails.KeyBunchNumber}}</p>
        </v-card-text>
      </v-card>

      <div class="my-5 landlord-notes">
        <v-card outlined>
          <v-card-title class="font-weight-light">{{selectedPropertyLettingDetails.MainLandlord.PersonType}}</v-card-title>
          <v-card-text>
            <span class="title font-weight-light">
              <span>{{selectedPropertyLettingDetails.MainLandlord.Title}} </span>
              <span>{{selectedPropertyLettingDetails.MainLandlord.Forename}} </span>
              <span>{{selectedPropertyLettingDetails.MainLandlord.Surname}}</span>
            </span>
            <div>
              <v-btn 
                v-if="selectedPropertyLettingDetails.MainLandlord.MobilePhone" 
                :href="`tel:${selectedPropertyLettingDetails.MainLandlord.MobilePhone}`"
                class="mb-1 mt-2 font-weight-medium" 
                text outlined 
                color="blue"
              >
                <v-icon left small>mdi-phone</v-icon>{{selectedPropertyLettingDetails.MainLandlord.MobilePhone}}
              </v-btn>
            </div>
            <div>
              <v-btn 
                v-if="selectedPropertyLettingDetails.MainLandlord.EmailAddress" 
                :href="`mailto:${selectedPropertyLettingDetails.MainLandlord.EmailAddress}?body=Dear%20${selectedPropertyLettingDetails.MainLandlord.Forename},`"
                class="my-1 font-weight-medium" 
                text outlined 
                color="blue"
              >
                <v-icon left small>mdi-at</v-icon>{{selectedPropertyLettingDetails.MainLandlord.EmailAddress}}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
      
    </div>
      <!--/sales instructions  -->
  </div>
</template>

<script>
import moment from 'moment/src/moment'

export default {
  props: [
    'selectedPropertyLettingDetails',
    'selectedPropertyLettingTenancyDetails'
  ],
  methods: {
    linkToMaps(value) {
      // let geoLocation = value.replace(/\s+/g, '+').toLowerCase();
      // let url = `https://www.google.com/maps/place/${geoLocation}`
      let url = `https://www.google.com/maps/place/${value.Latitude},${value.Longitude}`
      window.open(url, '_blank');
    },
    returnDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },
    returnFormattedPrice(price) {
      let newValue = parseFloat(price).toLocaleString('en-GB')
      return '£' + newValue
    },
  }
}
</script>
