<template>
  <!-- Component is imported into the PropertyCard in the Properties page -->
  <div>
    <v-card class>
      <v-card-title class="secondary white--text py-0">
        <v-container class="py-0">
          <v-row>
            <v-col cols="9">
              <p class="headline mb-0 font-weight-light">Book appointment</p>
            </v-col>
            <v-col class="text-right" cols="3">
              <v-btn @click="closeBookDialog" small text color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <v-container>
        <v-card solo flat class="pa-5">
           <TheCalendar />
        </v-card>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import moment from 'moment/src/moment'
import TheCalendar from '@/components/TheCalendar.vue';

export default {
  props: {
    Property: {
      type: Object
    },
    PropertyType: {
      type: String
    }
  },
  components: {
    TheCalendar
  },
  data: () => ({
    creatingAppointment: false,
    startTimeDialog: false,
    endTimeDialog: false,
    dateDialog: false,
    endDateDialog: false,
    valid: true,
    subject: "",
    allDay: false,
    date: "",
    endDate: "",
    startTime: "",
    endTime: "",
    // pre populated to Viewing
    appointmentType: "0304-f421-2742-8afa",
    comments: "",
    selectedProperty: "",
    selectedPropertyID: "",
    selectedPropertySubject: "",
    selectedPersons: [],
    assignedTo: '', //who appointment is assigned to
  }),
  created() {
    this.assignedTo = this.$store.state.Calendar.allDiaries[0] // assigning signed in user diary 

    let today = new Date();
    today.setDate(today.getDate() + 1);
    let tomorrow = today.toISOString().substr(0, 10);
    this.date = tomorrow;
    this.endDate = tomorrow;

    //setting start and end time
    // start time set top top of neares hour
    const hours = today.getHours()
    const minutes = today.getMinutes()
    if(minutes > 30) {
      const topOfHour = hours + 1
      this.startTime = `${topOfHour}:00`
      const topOfHourEnd = topOfHour + 1 
      this.endTime = `${topOfHourEnd}:00`

    } else {
      this.startTime = `${hours}:00` 
      const end = hours + 1
      this.endTime = `${end}:00`
    }

    // this.selectedProperty = this.Property; // sets the property that has been selected
  },
  computed: {
    ...mapState(["appointmentTypes"]),
    formattedStartDate() {
      return this.date ? moment(this.date).format("DD/MM/YYYY") : "";
    },
    formattedEndDate() {
      return this.endDate ? moment(this.endDate).format("DD/MM/YYYY") : "";
    }
  },
  methods: {
    closeBookDialog() {
      this.$emit("closeBookDialog", null);
    },
    setDates() {
      this.endDate = this.date;
      this.dateDialog = false;
    },
    setTimes() {
      const durationInMinutes = "60";
      const endTime = moment(this.startTime, "HH:mm")
        .add(durationInMinutes, "minutes")
        .format("HH:mm");
      this.endTime = endTime;
      this.startTimeDialog = false;
    },
    removeProperty(item) {
      const index = this.selectedProperties.indexOf(item);
      if (index > -1) {
        this.selectedProperties.splice(index, 1);
      }
    },
    removePerson(item) {
      const index = this.selectedPersons.indexOf(item);
      if (index > -1) {
        this.selectedPersons.splice(index, 1);
      }
    },
    //method receiving event from searchProperty component
    showProperties(property) {
      if (property) {
        this.selectedProperties.push(property);
      }
    },
    //method receiving event from searchPerson component
    showPersons(persons) {
      if (persons) {
        this.selectedPersons.push(persons);
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true;
      }
    },
    createAppointment() {
      // create appointment
      this.creatingAppointment = true;
      this.$store
        .dispatch("createAppointment", {
          Start: `${this.date}T${this.startTime}`,
          End: `${this.endDate}T${this.endTime}`,
          StaffID: this.assignedTo.StaffID,
          AppointmentType: this.appointmentType,
          Subject: this.subject,
          ExtraComments: this.comments
        })
        .then(response => {
          console.log("appointment created: " + response.data.substr(13, 19));
          const createdAppointmentOID = response.data.substr(13, 19);

          //link property[s]
          // if(this.selectedProperties.length) {

          // const propertyIDs = this.selectedProperties.map(x => x.OID)
          let propertyIDs = [];
          propertyIDs.push(this.selectedPropertyID);

          axios({
            method: "PUT",
            url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/appointments/${createdAppointmentOID}/link/properties`,
            data: propertyIDs,
            headers: {
              token: this.$store.state.user.LoginToken,
              api_key: this.$store.state.apiKey
            }
          }).then(() => {
            console.log("property linked OK");
            this.resetDialog();
          })
          if (this.selectedPersons.length) {
            const personsIDs = this.selectedPersons.map(x => x.OID);

            axios({
              method: "PUT",
              url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/appointments/${createdAppointmentOID}/link/people`,
              data: personsIDs,
              headers: {
                token: this.$store.state.user.LoginToken,
                api_key: this.$store.state.apiKey
              }
            })
              .then(() => {
                console.log("person linked OK");
                this.resetDialog();
              })
              .catch(err => {
                this.$emit("linkPersonError", err);
                this.resetDialog();
              });
          } else {
            //reset dialog inputs
            this.resetDialog();
          }
        });
    },
    resetDialog() {
      this.creatingAppointment = false;
      this.date = "";
      this.endDate = "";
      this.startTime = "";
      this.endTime = "";
      this.selectedProperty = "";
      this.selectedPersons = [];
      this.$store.dispatch("fetchAppointments");
      this.$emit("closeBookDialog", null);
    }
  }
};
</script>

