<template>
  <!-- This is the actual content of an appointment -->
  <!-- This component feeds into the Calendar and the AgendaTimeline (mobile to desktop) -->
  <div>
    <v-card-title
      class="rounded-t white--text d-flex align-center px-3 py-2"
      :class="appointment.myCalendar ? 'secondary' : appointment.color"
    >
      <div>
        <span
          class="subtitle-1 font-weight-light mb-0"
          :class="{ caption: isTooLong }"
          >{{ appointment.DisplayAppointmentType }}</span
        >
      </div>
      <v-spacer></v-spacer>
      <span class="overline font-weight-light mb-0"
        >{{ appointment.start | getTime }}-{{ appointment.end | getTime }}</span
      >
      <v-btn
        v-if="accessToAppointment(appointment)"
        class
        x-small
        @click.stop="showAppointment(appointment)"
        text
        color="white"
      >
        <v-spacer></v-spacer>
        <v-icon right x-small>mdi-pen</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="white py-2">
      <span class="ml-0 my-0 subtitle-1 font-weight-regular">
        {{ appointment.name }}
      </span>

      <v-row>
        <!-- Letting properties row -->
        <v-col
          class=""
          v-if="appointment.LinkedLettingsProperties.length"
          cols="12"
          sm="6"
          md="12"
        >
          <v-row
            v-for="(property, index) in appointment.LinkedLettingsProperties"
            :key="property.OID"
          >
            <v-col class="" v-if="index === 0" cols="12">
              <v-row>
                <v-col cols="8">
                  <v-btn
                    small
                    depressed
                    color="orange"
                    @click="
                      showPropertyInfo(
                        property,
                        appointment.OID,
                        'letting property'
                      )
                    "
                  >
                    <span
                      class="
                        font-weight-medium
                        text-caption
                        white--text
                        text-capitalize
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 170px"
                    >
                      {{ property.DisplayName }}
                    </span>
                  </v-btn>
                  <LettingPropertyDetails class="mt-1" :property="property" />
                </v-col>
                <v-col
                  v-if="
                    !showExtraLettings &&
                    appointment.LinkedLettingsProperties.length > 1
                  "
                  class="d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    depressed
                    small
                    outlined
                    color="orange"
                    @click="showExtraLettings = !showExtraLettings"
                  >
                    <span
                      >+{{
                        appointment.LinkedLettingsProperties.length - 1
                      }}</span
                    >
                  </v-btn>
                </v-col>
                <v-col
                  class="d-flex justify-end"
                  v-if="showExtraLettings"
                  cols="4"
                >
                  <v-btn
                    depressed
                    small
                    text
                    color="orange"
                    @click="showExtraLettings = !showExtraLettings"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- Extra lettings properties row -->
            <v-col
              class="mt-n3 py-0"
              v-if="index > 0 && showExtraLettings"
              cols="12"
            >
              <v-row>
                <v-col cols="12">
                  <v-btn
                    depressed
                    small
                    color="orange"
                    @click="
                      showPropertyInfo(
                        property,
                        appointment.OID,
                        'letting property'
                      )
                    "
                  >
                    <span
                      class="
                        font-weight-medium
                        text-caption
                        white--text
                        text-capitalize
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 170px"
                    >
                      {{ property.DisplayName }}
                    </span>
                  </v-btn>
                  <LettingPropertyDetails class="mt-1" :property="property" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class="py-0"
          v-if="appointment.LinkedLinkedSalesInstructios.length"
          cols="12"
          sm="6"
          md="12"
        >
          <!-- Sales properties row -->
          <v-row
            v-for="(
              property, index
            ) in appointment.LinkedLinkedSalesInstructios"
            :key="property.OID"
          >
            <v-col class="py-0" v-if="index === 0" cols="12">
              <v-row>
                <v-col cols="8">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    @click="
                      showPropertyInfo(
                        property,
                        appointment.OID,
                        'sales instruction'
                      )
                    "
                  >
                    <span
                      class="
                        font-weight-medium
                        text-caption
                        white--text
                        text-capitalize
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 170px"
                    >
                      {{ property.DisplayName }}
                    </span>
                  </v-btn>
                  <SalesInstructionsDetails class="mt-1" :property="property" />
                </v-col>
                <v-col
                  v-if="
                    appointment.LinkedLinkedSalesInstructios.length > 1 &&
                    !showExtraSales
                  "
                  class="d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    depressed
                    small
                    outlined
                    color="primary"
                    @click="showExtraSales = !showExtraSales"
                  >
                    <span
                      >+{{
                        appointment.LinkedLinkedSalesInstructios.length - 1
                      }}</span
                    >
                  </v-btn>
                </v-col>
                <v-col
                  class="d-flex justify-end"
                  v-if="showExtraSales"
                  cols="4"
                >
                  <v-btn
                    small
                    text
                    color="red"
                    @click="showExtraSales = !showExtraSales"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- Extra Sales properties -->
            <v-col
              class="mt-n3 py-0"
              v-if="index > 0 && showExtraSales"
              cols="12"
            >
              <v-row>
                <v-col cols="12">
                  <v-btn
                    depressed
                    small
                    color="primary"
                    @click="
                      showPropertyInfo(
                        property,
                        appointment.OID,
                        'sales instruction'
                      )
                    "
                  >
                    <span
                      class="
                        font-weight-medium
                        text-caption
                        white--text
                        text-capitalize
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 170px"
                    >
                      {{ property.DisplayName }}
                    </span>
                  </v-btn>
                  <SalesInstructionsDetails class="mt-1" :property="property" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          class=""
          cols="12"
          sm="6"
          md="12"
          v-if="appointment.LinkedUsers.length"
        >
          <!-- Users ------------------------------------------------------------------ -->
          <!-- ------------------------------------------------------------------------ -->
          <v-row
            v-for="(user, index) in appointment.LinkedUsers"
            :key="user.OID"
          >
            <v-col class="" v-if="index === 0">
              <v-row>
                <v-col cols="8">
                  <v-btn
                    depressed
                    small
                    @click="showPersonInfo(user)"
                    color="green"
                  >
                    <span
                      class="
                        white--text
                        font-weight-medium
                        text-caption text-capitalize
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 170px"
                    >
                      {{ user.DisplayName }}
                    </span>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="!showExtraUsers && appointment.LinkedUsers.length > 1"
                  class="d-flex justify-end"
                  cols="4"
                >
                  <v-btn
                    depressed
                    small
                    @click="showExtraUsers = !showExtraUsers"
                    outlined
                    color="success"
                  >
                    <span>+{{ appointment.LinkedUsers.length - 1 }}</span>
                  </v-btn>
                </v-col>
                <v-col
                  class="d-flex justify-end"
                  v-if="showExtraUsers"
                  cols="4"
                >
                  <v-btn
                    depressed
                    small
                    text
                    color="green"
                    @click="showExtraUsers = !showExtraUsers"
                  >
                    <v-icon>mdi-chevron-up</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <!-- Extra users row -->
            <v-col class="mt-n2" v-if="index > 0 && showExtraUsers">
              <v-row>
                <v-col cols="12" sm="6">
                  <v-btn
                    depressed
                    small
                    class=""
                    @click="showPersonInfo(user)"
                    color="green"
                  >
                    <span
                      class="
                        white--text
                        font-weight-medium
                        text-caption text-capitalize
                        d-inline-block
                        text-truncate
                      "
                      style="max-width: 170px"
                    >
                      {{ user.DisplayName }}
                    </span>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <blockquote v-if="appointment.Comment" class="blockquote font-weight-t">
        {{ appointment.Comment }}
      </blockquote>
    </v-card-text>
  </div>
</template>

<script>
import SalesInstructionsDetails from "@/components/Agendas/AgendaSalesInstructionsDetails";
import LettingPropertyDetails from "@/components/Agendas/AgendaLettingPropertyDetails";

export default {
  name: "appointment-card",
  components: {
    SalesInstructionsDetails,
    LettingPropertyDetails,
  },
  props: ["appointment"],
  data() {
    return {
      showExtraSales: false,
      showExtraLettings: false,
      showExtraUsers: false,
    };
  },
  methods: {
    showAppointment(appointment) {
      this.$emit("showAppointment", appointment);
    },
    showPropertyInfo(property, appointmentID, type) {
      const payload = {
        appointmentOID: appointmentID,
        propertyOID: property.OID,
      };
      this.$store.dispatch("getPropertyFeedback", payload).then((res) => {
        let feedbackText = res.data;
        this.$emit(
          "showPropertyInfo",
          property,
          appointmentID,
          type,
          feedbackText
        );
      });
    },
    showPersonInfo(person) {
      this.$emit("showPersonInfo", person);
    },
    accessToAppointment(appointment) {
      const myID = this.$store.state.sessionData.OID;
      if (appointment.CreatedBy === myID) {
        return true;
      } else if (appointment.Staff === myID) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    isTooLong() {
      if (
        this.appointment.DisplayAppointmentType === "Photos and Floor Plans "
      ) {
        return true;
      }
      return false;
    },
  },
  filters: {
    getTime(val) {
      return val.substr(11, 5);
    },
  },
};
</script>

<style>
</style>