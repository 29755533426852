<template>
  <div>
    <v-select
      class="mt-n1"
      v-if="$store.state.Calendar.allDiaries"
      @change="setDiary"
      disable-lookup
      :background-color="bgColor"
      outlined
      dense
      prepend-inner-icon="mdi-account"
      color="secondary"
      return-object
      item-text="StaffName"
      v-model="selectedDiaryArray"
      :items="$store.state.Calendar.allDiaries"
      label="Search"
      multiple
      clearable
      :menu-props="{ closeOnContentClick: true }"
    ></v-select>
  </div>
</template>

<script>
export default {
  name: "select-diary",
  props: {
    bgColor: {
      type: String,
      default: "secondary",
    },
  },

  data() {
    return {
      selectedDiaryArray: [],
    };
  },
  created() {
    if (this.$store.state.selectedColleagueDiary.length) {
      const diaries = this.$store.state.selectedColleagueDiary;
      diaries.forEach((diary) => this.selectedDiaryArray.push(diary));
    }
  },
  methods: {
    setDiary() {
      if (this.selectedDiaryArray.length) {
        const diaries = this.selectedDiaryArray;
        const newArray = diaries.map((diary, index) => {
          if (index === 0) {
            return {
              ...diary,
              color: "red lighten-3",
            };
          } else if (index === 1) {
            return {
              ...diary,
              color: "blue lighten-3",
            };
          } else if (index === 2) {
            return {
              ...diary,
              color: "green lighten-3",
            };
          } else if (index === 3) {
            return {
              ...diary,
              color: "purple lighten-3",
            };
          } else if (index === 4) {
            return {
              ...diary,
              color: "blue-grey lighten-3",
            };
          } else {
            return {
              ...diary,
              color: "light-green lighten-3",
            };
          }
        });
        this.$store.commit("SET_COLLEAGUES_DIARY", newArray);
        newArray.forEach((diary) => {
          this.$store
            .dispatch("fetchAppointments") // refresh looged in user appointments
            .then(() =>
              this.$store.dispatch("Calendar/getColleaguesDiary", diary)
            ); // fetch data for diary => which then pushes to appointments array
        });
      } else {
        this.$store.dispatch("fetchAppointments"); // refresh looged in user appointments
        this.$store.commit("CLEAR_COLLEAGUE_DIARY");
      }
    },
  },
};
</script>

