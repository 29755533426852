<template>
  <div class="the-calendar">
    <!-- Floating + btn -->
    <v-btn
      v-if="this.$route.path === '/negapp/calendar'"
      @click="createEvent"
      id="addButton"
      fab
      :large="$vuetify.breakpoint.smAndUp"
      bottom
      right
      fixed
      dark
      color="green"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <!-- Calendar -->
    <v-container fluid>
      <v-row class="fill-height mx-n4">
        <v-col class="px-0">
          <v-sheet class="py-0" height="64">
            <v-toolbar
              class="rounded"
              :tile="tileToolbar"
              flat
              color="secondary"
              dark
            >
              <v-btn outlined small @click="setToday">Today</v-btn>
              <v-btn class="d-none d-sm-flex" fab text @click="prev">
                <v-icon small>mdi-chevron-left</v-icon>
              </v-btn>
              <v-btn class="d-none d-sm-flex" fab text @click="next">
                <v-icon small>mdi-chevron-right</v-icon>
              </v-btn>
              <v-toolbar-title class="mb-1" v-if="$refs.calendar">
                <span class="ml-3 text-body-1 white--text">{{
                  $refs.calendar.title
                }}</span>
              </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-btn @click="selectDiaryDialog = true" class="d-lg-none" text>
                <v-icon>mdi-account-multiple</v-icon>
              </v-btn>
              <v-btn
                v-if="$route.path !== '/negapp/calendar'"
                class="d-lg-none mr-3"
                small
                text
                @click="toggleTimeline"
              >
                <v-icon>mdi-timeline-text-outline</v-icon>
              </v-btn>

              <div class="type-icons d-none d-lg-flex align-center">
                <SelectDiary class="d-none d-lg-flex mt-8" />

                <v-btn small text @click="toggleTimeline">
                  <v-icon>mdi-timeline-text-outline</v-icon>
                </v-btn>
                <v-btn small text @click="setCalendarToMonth">
                  <v-icon>mdi-calendar-month-outline</v-icon>
                </v-btn>
                <v-btn small text @click="type = 'week'">
                  <v-icon>mdi-calendar-week</v-icon>
                </v-btn>
                <v-btn small text @click="type = 'day'">
                  <v-icon>mdi-calendar-today</v-icon>
                </v-btn>
              </div>
              <div class="d-xs-flex hidden-lg-and-up">
                <v-menu class="" offset-y>
                  <template v-slot:activator="{ on }">
                    <v-btn outlined v-on="on" small>
                      <span>{{ typeToLabel[type] }}</span>
                      <v-icon right>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="type = 'day'">
                      <v-list-item-title>Day</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'week'">
                      <v-list-item-title>Week</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="type = 'month'">
                      <v-list-item-title>Month</v-list-item-title>
                    </v-list-item>
                    <v-list-item class="d-sm-none">
                      <v-list-item-title>Add calendar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </v-toolbar>
          </v-sheet>

          <v-sheet
            class="mt-1"
            height="600"
            v-touch="{
              left: () => swipe('Left'),
              right: () => swipe('Right'),
            }"
          >
            <!-- <v-calendar
              ref="calendar"
              v-model="focus"
              :events="appointments"
              :event-color="returnEventColor"
              :event-margin-bottom="3"
              event-overlap-mode="stack"
              event-overlap-threshold="30"
              event-name="DisplayAppointmentType"
              color="primary"
              :now="now"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:time="viewTime"
              @change="updateRange"
              first-interval="6"
              :weekdays="weekdays"
              interval-count="18"
              :interval-style="intervalStyle"
              show-week
            >
            </v-calendar> -->
            <v-calendar
              ref="calendar"
              v-model="focus"
              :events="appointments"
              :event-color="returnEventColor"
              :event-name="DisplayAppointmentType"
              color="primary"
              :now="now"
              :weekdays="weekdays"
              :interval-style="intervalStyle"
              :type="type"
              @click:event="showEvent"
              @click:more="viewDay"
              @click:date="viewDay"
              @click:time="viewTime"
              @change="updateRange"
              first-interval="6"
              interval-count="18"
            >
              <template v-slot:event="event" class="">
                <span
                  class="ml-1 font-weight-medium"
                  v-if="event.eventParsed.input.myCalendar"
                  >{{ event.eventParsed.input.DisplayAppointmentType }}</span
                >
                <span class="ml-1 font-weight-medium" v-else>{{
                  event.eventParsed.input.colleagueName
                }}</span>
                <span class="ml-1"
                  >{{ event.eventParsed.input.start | getTime }}-{{
                    event.eventParsed.input.end | getTime
                  }}</span
                >
              </template>
            </v-calendar>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <!-- Appointment Dialog -->
    <v-dialog v-model="eventDialog" max-width="620">
      <v-card min-height="100">
        <AppointmentCard
          :appointment="selectedElement"
          @showAppointment="showAppointment"
          @showPropertyInfo="showPropertyInfo"
          @showPersonInfo="showPersonInfo"
        />
      </v-card>
    </v-dialog>

    <!-- Create Appointment Dialog -->
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="viewCreateComponent"
      max-width="650"
      persistent
    >
      <CreateAppointment
        :selectedStartTime="this.selectedStartTime"
        :selectedEndTime="this.selectedEndTime"
        :selectedStartDate="this.selectedStartDate"
        @closeCreateComponent="closeCreateComponent"
        @linkPersonError="linkUnlinkError"
        @linkPropertyError="linkUnlinkError"
        v-if="viewCreateComponent"
      />
    </v-dialog>

    <!-- Edit event dialog -->
    <v-dialog
      :fullscreen="$vuetify.breakpoint.xsOnly"
      v-model="viewEditComponent"
      max-width="650"
      persistent
    >
      <EditAppointment
        @closeEditComponent="closeEditComponent"
        @closeEditNoRefresh="closeEditNoRefresh"
        @deleteAppointment="deleteAppointment"
        @linkPersonError="linkUnlinkError"
        @linkPropertyError="linkUnlinkError"
        v-if="viewEditComponent"
        :eventData="eventToEdit"
      />
    </v-dialog>

    <!-- Property info dialog -->
    <PropertyInfoDialog
      :propertyInfoDialog="propertyInfoDialog"
      :selectedProperty="selectedProperty"
      :selectedAppointmentID="selectedAppointmentID"
      :selectedPropertySalesDetails="selectedPropertySalesDetails"
      :selectedPropertyLettingDetails="selectedPropertyLettingDetails"
      :selectedPropertyLettingTenancyDetails="
        selectedPropertyLettingTenancyDetails
      "
      :selectedPropertyFeedback="selectedPropertyFeedback"
      :selectedPropertyType="selectedPropertyType"
      @closePropertyInfo="closePropertyInfo"
      @apiRequestSuccess="apiRequestSuccess"
      @apiRequestError="apiRequestError"
    />

    <!-- Person info dialog -->
    <PersonInfoDialog
      :personInfoDialog="personInfoDialog"
      :personInfoDialogLoading="personInfoDialogLoading"
      :personInfo="personInfo"
      @closePersonInfo="closePersonInfo"
    />

    <!-- Seelct diary dialog -->
    <v-dialog
      v-model="selectDiaryDialog"
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card min-height="200">
        <v-card-title class="blue white--text">
          Select colleague`s diary
          <v-spacer></v-spacer>
          <v-btn text color="white" @click="selectDiaryDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-5">
          <SelectDiary bgColor="white" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Snack Bar -->
    <v-snackbar
      :color="snackbarColor"
      v-model="snackbar"
      :timeout="snackbarTimeout"
    >
      {{ snackbarText }}
      <v-btn color="white" text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CreateAppointment from "@/components/Appointments/CreateAppointment";
import EditAppointment from "@/components/Appointments/EditAppointment";
import PersonInfoDialog from "@/components/Dialogs/PersonInfoDialog";
import PropertyInfoDialog from "@/components/Dialogs/PropertyInfoDialog";
import AppointmentCard from "@/components/Appointments/AppointmentCard";
import SelectDiary from "@/components/Appointments/SelectDiary.vue";

const stylings = {
  // setting the grey shade to establish NOW on the calendar
  // default (interval) {
  //   return undefined
  // },
  // workday (interval) {
  //   const inactive = interval.weekday === 0 ||
  //     interval.weekday === 6 ||
  //     interval.hour < 9 ||
  //     interval.hour >= 17
  //   const startOfHour = interval.minute === 0
  //   const dark = this.dark
  //   const mid = dark ? 'rgba(255,255,255,0.1)' : 'rgba(0,0,0,0.1)'

  //   return {
  //     backgroundColor: inactive ? (dark ? 'rgba(0,0,0,0.4)' : 'rgba(0,0,0,0.05)') : undefined,
  //     borderTop: startOfHour ? undefined : '1px dashed ' + mid,
  //   }
  // },
  past(interval) {
    return {
      backgroundColor: interval.past
        ? this.dark
          ? "rgba(0,0,0,0.4)"
          : "rgba(0,0,0,0.05)"
        : undefined,
    };
  },
};

export default {
  components: {
    CreateAppointment,
    EditAppointment,
    PersonInfoDialog,
    PropertyInfoDialog,
    AppointmentCard,
    SelectDiary,
  },
  props: {
    newAgendaDate: {
      type: String,
    },
  },
  data: () => ({
    tileToolbar: false,
    propertyFeedback: "",
    personInfoDialog: false,
    personInfoDialogLoading: false,
    personInfo: "",
    viewCreateComponent: false,
    eventToEdit: null,
    viewEditComponent: false,
    now: new Date().toISOString().substr(0, 19),
    today: new Date().toISOString().substr(0, 10),
    focus: new Date().toISOString().substr(0, 10),
    type: "week",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      // "4day": "4 Days"
    },
    start: null,
    end: null,
    selectedEvent: {},
    selectedElement: null,
    selectedOpen: false,
    weekdays: [1, 2, 3, 4, 5, 6, 0],
    selectedStartDate: "",
    selectedStartTime: "",
    selectedEndTime: "",
    eventDialog: false,
    snackbar: false,
    snackbarText: "",
    snackbarTimeout: 3500,
    snackbarColor: "",
    styleInterval: "past",
    propertyInfoDialog: false,
    selectedProperty: "",
    selectedAppointmentID: "",
    selectedPropertyType: "",
    selectedPropertyFeedback: "",
    selectedPropertySalesDetails: "",
    selectedPropertyLettingDetails: "",
    selectedPropertyLettingTenancyDetails: "",
    selectDiaryDialog: false,
  }),
  created() {
    this.$store.dispatch("sessionStatus").then(() => {
      this.$store.dispatch("fetchAppointmentTypes").then(() => {
        console.log("calendar has fetched appointments");
      });
    });
  },
  computed: {
    ...mapState(["appointments"]),
    intervalStyle() {
      return stylings[this.styleInterval].bind(this);
    },
    // title() { //set the title (week and month) of the calendar
    //   const { start, end } = this;
    //   if (!start || !end) {
    //     return "";
    //   }

    //   const startMonth = this.monthFormatter(start);
    //   const endMonth = this.monthFormatter(end);
    //   const suffixMonth = startMonth === endMonth ? "" : endMonth;

    //   const startYear = start.year;
    //   const endYear = end.year;
    //   const suffixYear = startYear === endYear ? "" : endYear;

    //   const startDay = start.day + this.nth(start.day);
    //   const endDay = end.day + this.nth(end.day);

    //   switch (this.type) {
    //     case "month":
    //       return `${startMonth} ${startYear}`;
    //     case "week":
    //       if(startMonth === endMonth) {
    //         return `${startDay} - ${endDay} ${startMonth}`
    //       } else {
    //         return `${startDay} ${startMonth}  - ${endDay} ${suffixMonth}`;
    //       }
    //     case "4day":
    //       return `${startDay} ${startMonth} ${startYear} - ${endDay} ${suffixMonth} ${suffixYear}`;
    //     case "day":
    //       return `${startMonth} ${startDay} ${startYear}`;
    //   }
    //   return "";
    // },
    monthFormatter() {
      return this.$refs.calendar.getFormatter({
        timeZone: "UTC",
        month: "long",
      });
    },
  },
  methods: {
    DisplayAppointmentType(event) {
      if (event.input.myCalendar) {
        return event.input.DisplayAppointmentType;
      } else {
        return event.input.colleagueName;
      }
    },
    apiRequestSuccess() {
      this.propertyInfoDialog = false;
      this.propertyFeedback = "";
      this.snackbar = true;
      this.snackbarColor = "green";
      this.snackbarText = "Property feedback added.";
    },
    apiRequestError() {
      this.propertyInfoDialog = false;
      this.propertyFeedback = "";
      this.snackbar = true;
      this.snackbarColor = "red";
      this.snackbarText = "Error. Could not add feedback.";
    },
    showPropertyInfo(property, appointmentID, type, feedback) {
      this.selectedProperty = property;
      this.selectedAppointmentID = appointmentID;
      this.selectedPropertyType = type;
      this.selectedPropertyFeedback = feedback;

      if (type === "letting property") {
        this.$store
          .dispatch("lettingPropertyDetails", property.OID)
          .then(({ data }) => {
            this.selectedPropertyLettingDetails = data;
            this.$store
              .dispatch("propertyTenanciesLatest", property.OID)
              .then(({ data }) => {
                this.selectedPropertyLettingTenancyDetails = data;
                this.propertyInfoDialog = true;
              });
          });
      } else if (type === "sales instruction") {
        this.$store
          .dispatch("salesInstructionsDetails", property.OID)
          .then(({ data }) => {
            this.selectedPropertySalesDetails = data;
            this.propertyInfoDialog = true;
          });
      }
    },
    closePropertyInfo() {
      this.propertyInfoDialog = false;
      this.selectedPropertySalesDetails = "";
      this.selectedPropertyLettingDetails = "";
      this.selectedPropertyLettingTenancyDetails = "";
    },
    showPersonInfo(person) {
      if (person.UserType === "Applicant Buyer") {
        this.$store.dispatch("getBuyerDetails", person.OID).then(({ data }) => {
          this.personInfo = data;
          this.personInfoDialog = true;
          this.personInfoDialogLoading = false;
        });
      } else if (person.UserType === "Applicant Tenant") {
        this.$store
          .dispatch("getTenantDetails", person.OID)
          .then(({ data }) => {
            this.personInfo = data;
            this.personInfoDialog = true;
            this.personInfoDialogLoading = false;
          });
      } else {
        this.personInfoDialogLoading = true;
        this.$store
          .dispatch("getPersonInfo", person.OID)
          .then(({ data }) => {
            this.personInfo = data;
            this.personInfoDialog = true;
            this.personInfoDialogLoading = false;
          })
          .catch(() => {
            this.personInfoDialogLoading = false;
            this.personInfoDialog = false;
            this.personInfo = "";
          });
      }
    },
    closePersonInfo() {
      this.personInfoDialog = false;
      this.personInfo = "";
    },
    returnEventColor(event) {
      if (event.myCalendar) {
        return "secondary";
      } else return event.color;
    },
    toggleTimeline() {
      this.$emit("toggleTimeline", null);
    },
    setCalendarToMonth() {
      this.type = "month";
      this.setToday();
    },
    linkUnlinkError(text) {
      this.snackbar = true;
      this.snackbarColor = "red";
      this.snackbarText = text.response.data;
    },
    closeCreateComponent() {
      this.viewCreateComponent = false;
      this.fetchAppointments();
    },
    closeEditComponent() {
      this.viewEditComponent = false;
      this.eventDialog = false;
      this.fetchAppointments();
    },
    closeEditNoRefresh() {
      this.viewEditComponent = false;
      this.eventDialog = false;
    },
    deleteAppointment(id) {
      this.$store
        .dispatch("deleteAppointment", id)
        .then(() => {
          this.viewEditComponent = false;
          this.eventDialog = false;
          this.snackbar = true;
          this.snackbarText = "Appointment deleted.";
          this.snackbarColor = "green";
          this.fetchAppointments();
        })
        .catch(() => {
          this.snackbar = true;
          this.snackbarText = "Sorry. Appointment cannot be deleted!";
          this.snackbarColor = "red";
        });
    },
    fetchAppointments() {
      this.$store.dispatch("fetchAppointments").then(() => {
        if (this.$store.state.selectedColleagueDiary.length) {
          console.log(
            "From TheCalendar: there are diaries saved. Adding diaries"
          );
          const diaries = this.$store.state.selectedColleagueDiary;
          diaries.forEach((diary) => {
            this.$store.dispatch("Calendar/getColleaguesDiary", diary);
          });
        }
      });
    },
    showAppointment(app) {
      this.eventToEdit = app;
      this.viewEditComponent = true;
    },
    swipe(direction) {
      if (direction === "Left") {
        this.next();
      } else {
        this.prev();
      }
    },
    createEvent() {
      this.selectedStartTime = "08:00";
      this.selectedEndTime = "09:00";
      this.selectedStartDate = this.today;
      this.viewCreateComponent = true;
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    viewTime({ date, hour }) {
      //when clicking on date hour slot
      const newHour = hour + 1;
      this.selectedStartDate = date;

      if (hour < 9) {
        this.selectedStartTime = "0" + hour + ":00";
        this.selectedEndTime = "0" + newHour + ":00";
      } else if (hour == 9) {
        this.selectedStartTime = "0" + hour + ":00";
        this.selectedEndTime = newHour + ":00";
      } else if (hour >= 10) {
        this.selectedStartTime = hour + ":00";
        this.selectedEndTime = newHour + ":00";
      }
      this.viewCreateComponent = true;
    },
    setToday() {
      this.focus = this.today;
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    // showEvent ({ nativeEvent, event }) {
    //   const open = () => {
    //     this.selectedEvent = event
    //     this.selectedElement = nativeEvent.target
    //     setTimeout(() => this.selectedOpen = true, 10)
    //   }

    //   if (this.selectedOpen) {
    //     this.selectedOpen = false
    //     setTimeout(open, 10)
    //   } else {
    //     open()
    //   }

    //   nativeEvent.stopPropagation()
    // },
    showEvent({ nativeEvent, event }) {
      this.selectedElement = event;
      this.eventDialog = true;

      if (this.viewCreateComponent) {
        this.viewCreateComponent = false;
      }

      nativeEvent.stopPropagation();
    },
    updateRange({ start, end }) {
      // You could load events from an outside source (like database) now that we have the start and end dates on the calendar
      if(this.start !== undefined && this.end !== undefined){
      this.start = start.date;
      this.end = end.date;

      this.$store.dispatch("fetchAppointmentWithDates", {start : this.start, end: this.end});
      }
    },
    nth(d) {
      return d > 3 && d < 21
        ? "th"
        : ["th", "st", "nd", "rd", "th", "th", "th", "th", "th", "th"][d % 10];
    },
  },
  filters: {
    getTime(val) {
      return val.substr(11, 5);
    },
  },
  watch: {
    focus(val) {
      this.$emit("newFocus", val);
    },
    newAgendaDate(val) {
      this.type = "week";
      this.focus = val;
    },
  },
};
</script>

<style scoped>
/* #addButton {
  z-index: 100;
  position: absolute;
  bottom: 5%;
  right: 5%;
} */

.the-calendar {
  margin-top: -20px;
}

#addButton {
  margin-bottom: 75px;
}
</style>