<template>
  <div>
    <v-card class="" v-if="eventData">
      <v-card-title class="secondary white--text py-0"  primary-title>
        <v-container class="py-0">
          <v-row>
            <v-col cols="9">
              <p class="headline mb-0 font-weight-light">Edit appointment</p>
            </v-col>
            <v-col class="text-right" cols="3">
              <v-btn @click="updateAppointment" small text color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>          
        </v-container>
      </v-card-title>

      <v-container>
        <v-form 
          @submit.prevent="updateAppointment" 
          lazy-validation
          v-model="valid"
          ref="form"
          >
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  :items="appointmentTypes"
                  item-text="Name"
                  item-value="OID"
                  label="Appointment type"
                  v-model="appointmentType"
                  :rules="[v => !!v || 'Item is required']"
                  required
                  prepend-icon="mdi-playlist-check"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="subject"
                  type="text"
                  label="Subject"
                  required
                  prepend-icon="mdi-text-subject"
                ></v-text-field>
              </v-col>
                <!-- <v-switch
                  v-model="allDay"
                  label="All day"
                ></v-switch> -->
            </v-row>

            <v-row>
              <v-col cols="6">
                <v-text-field
                  :value="formattedStartDate"
                  label="Start"
                  readonly
                  :rules="[v => !!v || 'Start date is required']"
                  required
                  prepend-icon="mdi-calendar-month-outline"
                  @click="dateDialog = true"
                ></v-text-field>
                <v-dialog
                  v-model="dateDialog"
                  persistent :overlay="false"
                  max-width="300px"
                  transition="dialog-transition"
                >
                  <v-date-picker v-model="date" @input="setDates"></v-date-picker>
                </v-dialog> 
              </v-col>
              <v-col cols="6">
                <v-text-field
                  :value="formattedEndDate"
                  label="End"
                  readonly
                  :rules="[v => !!v || 'End date is required']"
                  required
                  prepend-icon="mdi-calendar-month-outline"
                  @click="endDateDialog = true"
                ></v-text-field>
                <v-dialog
                  v-model="endDateDialog"
                  persistent :overlay="false"
                  max-width="300px"
                  transition="dialog-transition"
                >
                  <v-date-picker v-model="endDate" @input="endDateDialog = false"></v-date-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <!-- TIME SELECTORS -->
            <v-row>
              <v-col cols="6">
                <v-text-field
                  v-model="startTime"
                  label="Start"
                  readonly
                  :rules="[v => !!v || 'Start time is required']"
                  required
                  prepend-icon="mdi-clock-outline"
                  @click="startTimeDialog = true"
                ></v-text-field>
                <v-dialog
                  v-model="startTimeDialog"
                  persistent :overlay="false"
                  max-width="300px"
                  transition="dialog-transition"
                >
                  <v-time-picker
                    format="24hr"
                    v-model="startTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startTimeDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="setTimes()">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="endTime"
                  label="End"
                  readonly
                  :rules="[v => !!v || 'End time is required']"
                  required
                  prepend-icon="mdi-clock-outline"
                  @click="endTimeDialog = true"
                ></v-text-field>
                <v-dialog
                  v-model="endTimeDialog"
                  persistent :overlay="false"
                  max-width="300px"
                  transition="dialog-transition"
                >
                  <v-time-picker
                    format="24hr"
                    v-model="endTime"
                    full-width
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endTimeDialog = false">Cancel</v-btn>
                    <v-btn text color="primary" @click="endTimeDialog = false">OK</v-btn>
                  </v-time-picker>
                </v-dialog>
              </v-col>
            </v-row>

            <v-row v-if="eventData.LinkedLettingsProperties.length || eventData.LinkedLinkedSalesInstructios.length">
              <v-col cols="12" sm="6">
                <!-- Previously linked properties -->
                <div v-if="eventData.LinkedLettingsProperties.length">
                  <div class="d-flex align-center">
                    <v-icon color="grey darken-1">mdi-home</v-icon><span class="ml-1">Linked Letting Properties</span>
                  </div>
                  <div class="my-1" v-for="property in linkedProperties" :key="property.OID">
                    <v-chip
                      label
                      block
                      class="ma-2"
                      id="chips"
                      close
                      color="green"
                      text-color="white"
                      @click:close="unlinkItem(property, 'properties', 'lettingProperty')"
                    >
                      <span class="d-inline-block text-truncate" style="max-width: 190px;">
                        {{ property.DisplayName }}
                      </span>
                    </v-chip>
                  </div>
                </div>
                <!-- Previously Sales Instructions -->
                <div v-if="eventData.LinkedLinkedSalesInstructios.length">
                  <div class="d-flex align-center">
                    <v-icon color="grey darken-1">mdi-home</v-icon><span class="ml-1">Linked Sales Instructions</span>
                  </div>
                  <div class="my-1" v-for="property in linkedSalesInstructions" :key="property.OID">
                    <v-chip
                      label
                      block
                      class="ma-2"
                      id="chips"
                      close
                      color="green"
                      text-color="white"
                      @click:close="unlinkItem(property, 'properties', 'salesInstruction')"
                    >
                      <span class="d-inline-block text-truncate" style="max-width: 190px;">
                        {{ property.DisplayName }}
                      </span>
                    </v-chip>
                  </div>
                </div>
              </v-col>
              <v-col cols="12" sm="6">
                <!-- Previously linked people -->
                <div v-if="eventData.LinkedUsers.length">
                  <div class="d-flex align-center">
                    <v-icon color="grey darken-1">mdi-account</v-icon><span class="ml-1">Linked People</span>
                  </div>
                  <div v-for="person in linkedUsers" :key="person.OID">
                    <v-chip
                      label
                      block
                      class="ma-2"
                      id="chips"
                      close
                      @click:close="unlinkItem(person, 'people')"
                      color="blue"
                      text-color="white"
                    >
                      <span class="d-inline-block text-truncate" style="max-width: 190px;">
                        {{ person.DisplayName }}
                      </span>
                    </v-chip>
                  </div>
                </div>
              </v-col>
            </v-row>

            <v-row>
              <!-- Search Property Component -->
              <v-col cols="12" sm="6">
                <SearchProperty
                  color="blue"
                  @selectedProperties="showProperties" 
                />

                <div v-for="property in selectedProperties" :key="property.OID">
                  <v-chip
                    label
                    block
                    class="ma-2"
                    close
                    id="chips"
                    @click:close="removeProperty(property)"
                    color="green lighten-3"
                    text-color="white"
                  >
                    {{ property.Subject }}
                  </v-chip>
                </div>  
              </v-col>

              <!-- Search Person Component -->
              <v-col cols="12" sm="6">
                <SearchPerson
                  color="blue"
                  @selectedPersons="showPersons" 
                />

                <div v-for="person in selectedPersons" :key="person.OID">
                  <v-chip
                    label
                    block
                    class="ma-2"
                    close
                    id="chips"
                    @click:close="removePerson(person)"
                    color="blue lighten-3"
                    text-color="white"
                  >
                    {{ person.Subject }}
                  </v-chip>
                </div>  
              </v-col>
            </v-row>

            <!-- Textarea -->
            <v-row>
              <v-col cols="12" sm="6">
                <v-textarea
                  class="mt-3"
                  name="input-7-1"
                  label="Comments"
                  v-model="comments"
                  height="52"
                  prepend-icon="mdi-message-outline"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  filled background-color="primary" dark
                  class="mt-sm-5"
                  :items="$store.state.Calendar.allDiaries"
                  return-object
                  item-text="StaffName"
                  v-model="assignedTo"
                  label="Book appointment to:"
                ></v-select>
              </v-col>
            </v-row>

            <div class="mt-n1 mb-12 pb-10 d-flex justify-space-between">
              <v-btn class="mx-3" outlined fab small color="red" @click="$emit('deleteAppointment', eventData.OID)">
                <v-icon color="red">mdi-delete</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn class="mr-2" :disabled="!valid" type="submit" width="175" height="42" @click="validate" color="success">Save</v-btn>
            </div>

        </v-form>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import SearchProperty from '@/components/Searches/SearchProperty'
import SearchPerson from '@/components/Searches/SearchPerson'
import axios from 'axios'
import moment from 'moment/src/moment'

export default {
  name: 'edit-component',
  props: [
    'eventData'
    ],
  components: {
    SearchProperty,
    SearchPerson
  },
  data: () => ({
    startTimeDialog: false,
    endTimeDialog: false,
    dateDialog: false,
    endDateDialog: false,
    valid: true,
    subject: '',
    allDay: false,
    menu: false,
    menu2: false,
    date: '',
    endDate: '',
    startTime: '08:00',
    endTime: '09:00',
    appointmentType: '',
    comments: '',
    selectedProperties: [],
    selectedPersons: [],
    linkedUsers: [],
    linkedProperties: [],
    linkedSalesInstructions: [],
    assignedTo: '' // appointment assigned to
  }),
  created() {
    const allDiaries = this.$store.state.Calendar.allDiaries
    const assignedToObject = allDiaries.find( item => item.StaffID ===  this.eventData.Staff )
    // const objectIndex = allDiaries.findIndex(assignedToObject)

    this.assignedTo = assignedToObject//assigning my diary 

    if(this.eventData) {
      this.appointmentType = this.eventData.AppointmentType
      this.subject = this.eventData.name
      this.comments = this.eventData.Comment
      this.startTime = this.eventData.start.substr(11, 5)
      this.endTime = this.eventData.end.substr(11, 5)
      this.date = this.eventData.start.substr(0, 10)
      this.endDate = this.eventData.end.substr(0, 10)
      this.linkedUsers = this.eventData.LinkedUsers
      this.linkedProperties = this.eventData.LinkedLettingsProperties
      this.linkedSalesInstructions = this.eventData.LinkedLinkedSalesInstructios
    }
  },
  computed: {
    ...mapState(['appointmentTypes']),
      formattedStartDate () {
        return this.date ? moment(this.date).format('DD/MM/YYYY') : ''
      },
      formattedEndDate () {
        return this.endDate ? moment(this.endDate).format('DD/MM/YYYY') : ''
      }
  },
  methods: {
    setDates() {
      this.endDate = this.date
      this.dateDialog = false
    },
    setTimes() {
      const durationInMinutes = '60';
      const endTime = moment(this.startTime, 'HH:mm').add(durationInMinutes, 'minutes').format('HH:mm')
      this.endTime = endTime
      this.startTimeDialog = false
    },
    unlinkTest(id) {
      console.log('this appointment ' + id)
    },
    deleteAppointment(id) {
      console.log(id)
    },
    unlinkItem(obj, item, type) {
      const ID = [obj.OID]
      axios({
        method: 'DELETE',
        url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/appointments/${this.eventData.OID}/unlink/${item}`,
        data: ID,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      if(item === 'people') {
        const index = this.linkedUsers.indexOf(obj)
        if(index > -1) {
          this.linkedUsers.splice(index, 1)
        }
      } else if(type === 'lettingProperty') {
          const index = this.linkedProperties.indexOf(obj)
          if(index > -1) {
            this.linkedProperties.splice(index, 1)
          }
        }
        else if(type === 'salesInstruction') {
          const index = this.linkedSalesInstructions.indexOf(obj)
          if(index > -1) {
            this.linkedSalesInstructions.splice(index, 1)
          }
        }

    },
    removeProperty(item) {
      const index = this.selectedProperties.indexOf(item)
      if(index > -1) {
        this.selectedProperties.splice(index, 1)
      }
    },
    removePerson(item) {
      const index = this.selectedPersons.indexOf(item)
      if(index > -1) {
        this.selectedPersons.splice(index, 1)
      }
    },
    //method receiving event from searchProperty component
    showProperties(property) {
      if(property) {
        this.selectedProperties.push(property) 
      }
    },
    //method receiving event from searchPerson component
    showPersons(persons) {
      if(persons) {
        this.selectedPersons.push(persons) 
      }
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.snackbar = true
      }
    },
    updateAppointment() {
      const appointment = {
        Start: `${this.date}T${this.startTime}`,
        End: `${this.endDate}T${this.endTime}`,
        StaffID: this.assignedTo.StaffID,
        AppointmentType: this.appointmentType,
        Subject: this.subject,
        ExtraComments: this.comments
      }
      axios({
        method: 'PUT',
        url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/appointments/${this.eventData.OID}/update`,
        data: appointment,
        headers: {
          token: this.$store.state.user.LoginToken,
          api_key: this.$store.state.apiKey,
        }
      })
      .then( () => {
        console.log('Appointment successfully updated')

        //link property[s]
        if(this.selectedProperties.length) {

          const propertyIDs = this.selectedProperties.map(x => x.OID)

          axios({
            method: 'PUT',
            url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/appointments/${this.eventData.OID}/link/properties`,
            data: propertyIDs,
            headers: {
              token: this.$store.state.user.LoginToken,
              api_key: this.$store.state.apiKey,
            }
          })
          .then( () => {
            console.log('property linked OK')
            this.resetDialog()

          })
          .catch( err => {
            this.$emit('linkPropertyError', err)
            this.resetDialog()
          })
        }
        //link person[s]
        else if(this.selectedPersons.length) {
          
          const personsIDs = this.selectedPersons.map(x => x.OID)

          axios({
            method: 'PUT',
            url: `/corporate/agentapp/letmcletting/${this.$store.state.user.ClientName}/appointments/${this.eventData.OID}/link/people`,
            data: personsIDs,
            headers: {
              token: this.$store.state.user.LoginToken,
              api_key: this.$store.state.apiKey,
            }
          })
          .then( () => {
            console.log('person linked OK')
            this.resetDialog()
          })
          .catch( err => {
            this.$emit('linkPersonError', err)
            this.resetDialog()
          })
        }
        else {
          //reset dialog inputs
          this.resetDialog()
        }

      })
    },
    resetDialog() {
      this.date = ''
      this.endDate = ''
      this.startTime = ''
      this.endTime = ''
      this.selectedProperties = []
      this.$emit('closeEditComponent', null)
    }
  },
  watch: {
    allDay(oldValue, newValue) {
      if(newValue == true) {
        console.log('all day value is false')
          this.startTime = ''
          this.endTime = ''
      } else if (newValue == false) {
          console.log('new value is true')
            this.startTime = '09:00'
            this.endTime = '17:00'
      }

    }
  }
}
</script>

<style scoped>
#chips {
  font-size: 10px;
}
.unlinked {
  text-decoration: line-through;
}
</style>