<template>
  <div class="sticky">
    <v-row class="white d-none d-lg-flex">
      <v-col class="mt-n5 mb-n8" cols="12" lg="8">
        <v-row class="mt-3" align="center">
          <v-col class="" cols="12" lg="2">
            <v-select
              v-model="minBeds"
              :items="minBedNumber"
              label="Min beds"
              dense
              outlined
              @change="refresh"
            ></v-select>
          </v-col>
          <v-col class="" cols="12" lg="2">
            <v-select
              v-model="maxBeds"
              :items="maxBedNumber"
              label="Max beds"
              dense
              outlined
              @change="refresh"
            ></v-select>
          </v-col>
          <v-col class="" cols="12" lg="2">
            <v-select
              v-model="minPrice"
              :items="returnPrices"
              item-text="displayValue"
              item-value="value"
              label="Min price"
              dense
              outlined
              :menu-props="{ auto: false, maxHeight: 500 }"
              @change="refresh"
            ></v-select>
          </v-col>
          <v-col class="" cols="12" lg="2">
            <v-select
              v-model="maxPrice"
              :items="returnPrices"
              item-text="displayValue"
              item-value="value"
              label="Max price"
              dense
              outlined
              :menu-props="{ auto: false, maxHeight: 500 }"
              @change="refresh"
            ></v-select>
          </v-col>
          <v-col class="" cols="12" lg="4">
            <v-select
              v-model="propertyTypes"
              :items="$store.state.propertyTypes"
              item-text="Name"
              item-value="Value"
              label="Property type"
              multiple
              dense
              outlined
              :menu-props="{ auto: false, maxHeight: 500 }"
              @change="refresh"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
      <v-col class="mt-1" cols="12" lg="4">
        <v-row>
          <v-col cols="9">
            <!--Search -->
            <slot></slot>
          </v-col>
          <v-col cols="3">
            <v-btn @click="refreshDefault" outlined small color="success">
              <span class="text-capitalize">clear</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "desktop-filters",
  props: ["type"],
  data() {
    return {
      minBeds: "",
      maxBeds: "",
      minPrice: "",
      maxPrice: "",
      propertyTypes: [],
      minBedNumber: ["No min", 1, 2, 3, 4, 5, 6, 7, 8],
      maxBedNumber: ["No max", 1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  methods: {
    refreshDefault() {
      this.$emit("refreshDefault"); // resetting all filters
      this.minBeds = "";
      this.maxBeds = "";
      this.minPrice = "";
      this.maxPrice = "";
      this.propertyTypes = [];
    },
    refresh() {
      if (!this.propertyTypes.length) {
        this.refreshDefault;
      } else {
        this.$emit("refreshFilters", {
          //emits new paramaters for filtering
          minPrice: this.minPrice,
          maxPrice: this.maxPrice,
          minBeds: this.minBeds,
          maxBeds: this.maxBeds,
          propertyTypes: this.propertyTypes,
        });
      }
    },
  },
  computed: {
    returnPrices() {
      if (this.type === "sales") {
        return this.salesPrices;
      }
      return this.lettingPrices;
    },
    lettingPrices() {
      let prices = [];
      for (let i = 100; i <= 500; i += 50) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 600; i <= 1500; i += 100) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 1750; i <= 3000; i += 250) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      return prices;
    },
    salesPrices() {
      let prices = [];
      for (let i = 50000; i <= 300000; i += 10000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 325000; i <= 500000; i += 25000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 500000; i <= 1000000; i += 50000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 1250000; i <= 3000000; i += 250000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 4000000; i <= 5000000; i += 1000000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 4000000; i <= 5000000; i += 1000000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      return prices;
    },
  },
};
</script>

<style>
</style>