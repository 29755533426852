<template>
  <!-- Property info dialog -->
  <!-- This dialog opens when user clicks on the property button inside an appointment card (sales & lettings) -->
  <!-- It takes 2 components: SalesInstructionsDetails ^ LettingsPropertiesDetails -->
  <v-dialog
    max-width="600px"
    v-model="propertyInfoDialog"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    persistent 
    transition="dialog-transition"
  >
    <v-card>
      <v-card-title class="py-0 primary white--text" primary-title>
        <v-row class="py-0 my-0">
          <v-col cols="10">
            <span class="my-0 py-0 subtitle-1 font-weight-light">{{ selectedProperty.DisplayName }}</span>
          </v-col>
          <v-col cols="2">
            <v-row justify="end">
              <v-btn text @click="closePropertyInfo" color="white">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-row>
          </v-col>
        </v-row>
      </v-card-title>

      <v-card-text class="px-5 pb-0 mb-0">
         <!--Existing feedback  -->
         <v-card v-if="selectedPropertyFeedback !== '' " class="mt-5" outlined>
          <v-card-title class="green--text font-weight-regular">
            Property feedback
            <v-spacer></v-spacer>
            <v-btn @click.stop="myFeedbackVisible = !myFeedbackVisible" small color="success">
              <span class="text-capitalize" v-if="!myFeedbackVisible">add feedback</span>
              <span class="text-capitalize" v-else>Hide my feedback</span>
            </v-btn>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-textarea
              solo flat
              rows="2"
              readonly
              color="green"
              class="font-weight-light"
              :value="selectedPropertyFeedback"
              name="input-7-4"
              placeholder="Enter your feedback..."
            ></v-textarea>           
          </v-card-text>
        </v-card>        
        <!-- Feedback Text area -->
        <v-card v-if="myFeedbackVisible || selectedPropertyFeedback === '' " class="mt-5" outlined>
          <v-card-title class="font-weight-light">
            Add my feedback
            <v-spacer></v-spacer>
            <v-icon small>mdi-pencil</v-icon>
          </v-card-title>
          <v-card-text class="pb-0">
            <v-textarea
              rows="2"
              outlined
              class="font-weight-light"
              v-model="propertyFeedback"
              color="green"
              name="input-7-4"
              placeholder="Enter your feedback..."
            ></v-textarea>           
          </v-card-text>

          <v-card-actions class="px-5 pb-3 pt-0">
            <v-btn
              :disabled="!propertyFeedback" 
              class="px-5 font-weight-regular text-capitalize white--text" 
              color="green" 
              @click="submitPropertyFeedback(propertyFeedback)"
            >
            Submit
            </v-btn>
            <v-spacer></v-spacer>

          </v-card-actions>
        </v-card>

        <!-- Sales details rendering component -->
        <SalesInstructionsDetails 
          v-if="selectedPropertyType === 'sales instruction' "
          :selectedPropertySalesDetails="selectedPropertySalesDetails"
        />

        <!-- Letting property details rendering component -->
        <LettingPropertyDetails
          v-if="selectedPropertyType === 'letting property' "
          :selectedPropertyLettingDetails="selectedPropertyLettingDetails"
          :selectedPropertyLettingTenancyDetails="selectedPropertyLettingTenancyDetails"
        />

      <div class="create-space"></div>

      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import SalesInstructionsDetails from './SalesInstructionsDetails'
import LettingPropertyDetails from './LettingPropertiesDetails'

export default {
  components: {
    SalesInstructionsDetails,
    LettingPropertyDetails
  },
  props: [
    'propertyInfoDialog',
    'selectedProperty',
    'selectedAppointmentID',
    'selectedPropertySalesDetails',
    'selectedPropertyLettingDetails',
    'selectedPropertyLettingTenancyDetails',
    'selectedPropertyFeedback',
    'selectedPropertyType'
  ],
  data() {
    return {
      propertyFeedback: '',
      myFeedbackVisible: false
    }
  },
  methods: {
    closePropertyInfo() {
      this.propertyFeedback = ''
      this.$emit('closePropertyInfo')
    },
    submitPropertyFeedback(feedback) {
      let payload = {
        feedbackText: feedback,
        appointmentID: this.selectedAppointmentID,
        propertyID: this.selectedProperty.OID
      }
      this.$store.dispatch('addPropertyFeedback', payload)
        .then(() => {
          this.propertyFeedback = ''
          this.myFeedbackVisible = false
          this.$emit('apiRequestSuccess')
        })
        .catch(() => {
          this.propertyFeedback = ''
          this.$emit('apiRequestError')
        })

    },
  },
  created() {

  }
}
</script>

<style scoped>
  .create-space {
    width: 100%;
    height: 50px;
  }
</style>