<template>
  <div class="px-0 mx-0">
    <span class="font-weight-light caption" v-if="details.Beds">{{details.Beds}} 
      <span v-if="details.Beds > 1"> beds </span>   
      <span v-else> bed </span>   
    </span>
    <span class="font-weight-light caption">to LET </span>
    <span class="font-weight-light caption" v-if="tenancyDetails.AdvertisedRent">&#64; {{ returnFormattedPrice(tenancyDetails.AdvertisedRent) }} </span>
    <span class="font-weight-light caption" v-if="tenancyDetails.StartDate">available from {{ returnDate(tenancyDetails.StartDate) }}</span>

    <!-- <p class="pa-0 ma-0 font-weight-medium">To let</p>
    <p v-if="details.Beds" class="ma-0 pa-0">Beds: {{details.Beds}}</p>
    <p v-if="tenancyDetails.AdvertisedRent" class="ma-0 pa-0">Rent: {{ returnFormattedPrice(tenancyDetails.AdvertisedRent) }}</p>
    <p v-if="tenancyDetails.StartDate" class="ma-0 pa-0">Available from: {{ returnDate(tenancyDetails.StartDate) }}</p> -->
  </div>
</template>

<script>
import moment from 'moment/src/moment'
export default {
  props: ['property'],
  data() {
    return {
      details: '',
      tenancyDetails: ''
    }
  },
  methods: {
    returnDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },
    returnFormattedPrice(price) {
      let newValue = parseFloat(price).toLocaleString('en-GB')
      return '£' + newValue
    }
  },
  created() {
    this.$store.dispatch('lettingPropertyDetails', this.property.OID)
      .then( ({data}) => {
        this.details = data
      })
    this.$store.dispatch('propertyTenanciesLatest', this.property.OID)
      .then( ({data}) => {
        this.tenancyDetails = data
      })
    } 
  }
</script>

