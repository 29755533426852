<template>
  <!-- Person info dialog -->
  <v-dialog
    v-model="personInfoDialog"
    persistent 
    max-width="500px"
    transition="dialog-transition"
  >
    <v-card
      :loading="personInfoDialogLoading"
    >
      <v-card-title class="primary white--text font-weight-light" primary-title>
        <p class="my-0 py-0">{{personInfo.Title}} <span>{{personInfo.Forename}} {{personInfo.Surname}}</span></p>
        <v-spacer></v-spacer>
        <v-btn text @click="closePersonInfo" color="white">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="py-3">
        <p v-if="personInfo.PersonType" class="title font-weight-light">{{personInfo.PersonType}}</p>
        <div class="d-flex flex-wrap">
          <div v-if="personInfo.MobilePhone">
            <v-btn outlined color="blue" class="mr-5 mb-5 text-lowercase body-2" :href="`tel:${personInfo.MobilePhone}`">
              <v-icon left>mdi-phone</v-icon>{{personInfo.MobilePhone}}
            </v-btn>
          </div>
          <div v-if="personInfo.EmailAddress">
            <v-btn outlined color="blue" class="text-lowercase body-2" :href="`mailto:${personInfo.EmailAddress}?body=Dear%20${personInfo.Forename},`">
              <v-icon small left>mdi-at</v-icon>{{personInfo.EmailAddress}}
            </v-btn>
          </div>
        </div>


        <v-card v-if="personInfo.PersonType === 'Applicant Buyer'" outlined class="my-5">
          <v-card-text class="font-weight-light" v-if="personInfo.PersonType === 'Applicant Buyer'">
            <p v-if="personInfo.MaxPrice">Max: £{{personInfo.MaxPrice}}</p>
            <p v-if="personInfo.MinBeds">Min: {{personInfo.MinBeds}} bed(s)</p>
            <p v-if="personInfo.BuyingPosition">{{ returnReadableString(personInfo.BuyingPosition) }}</p>
            <p v-if="personInfo.SellingPosition">{{ returnReadableString(personInfo.SellingPosition) }}</p>
          </v-card-text>
        </v-card>

        <v-card v-if="personInfo.PersonType === 'Applicant Tenant'" outlined class="my-5">
          <v-card-text class="font-weight-light" v-if="personInfo.PersonType === 'Applicant Tenant'">
            <p v-if="personInfo.MaxRent">Max rent: £{{personInfo.MaxRent}}</p>
            <p v-if="personInfo.MinimumBeds && personInfo.MaximumBeds">{{personInfo.MinimumBeds}} to {{personInfo.MaximumBeds}} bed(s)</p>
            <p v-if="personInfo.LatestMoveDate">Latest move in by {{ personInfo.LatestMoveDate }}</p>
          </v-card-text>
        </v-card>


      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: [
    'personInfoDialog',
    'personInfoDialogLoading',
    'personInfo'
  ],
  methods: {
    closePersonInfo() {
      this.$emit('closePersonInfo')
    },
    returnReadableString(value) {
      return value.replace(/([A-Z])/g, ' $1').trim()
    }
  }
}
</script>

<style>

</style>