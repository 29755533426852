<template>
  <div class="sticky">
    <v-tabs
      color="primary"
      slider-color="primary"
      center-active
      background-color="grey lighten-4"
      show-arrows
    >
      <v-tab ripple>
        <span class="d-sm-none">To rent</span>
        <span class="d-none d-sm-block">Properties to rent</span>
      </v-tab>
      <v-tab ripple>
        <span class="d-sm-none">For sale</span>
        <span class="d-none d-sm-block">Properties for sale</span>
      </v-tab>
      <v-spacer></v-spacer>
      <v-tab disabled>
        <span class="d-none d-sm-flex text-capitalize font-weight-regular">{{ searchResult }} properties</span>
      </v-tab>
      <v-tab-item>
        <LettingProperties @searchResult="updateSearchNumber" />
      </v-tab-item>
      <v-tab-item>
        <SalesInstructions @searchResult="updateSearchNumber" />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import LettingProperties from '@/components/Properties/LettingProperties'
import SalesInstructions from '@/components/Properties/SalesInstructions'

export default {
  components: {
    LettingProperties,
    SalesInstructions,
  },
  data() {
    return {
      searchResult: ''
    }
  },
  created() {
    this.$store.commit("EXPIRE_LOGIN");
    if(this.$store.state.user !== ''){
      if(!this.$store.state.Calendar.allDiaries) { // add all diaries to allow appointments to be assigned
        this.$store.dispatch('Calendar/listAllDiaries')
      }
    }
    else{
      this.$router.push('/');
    }
  },
  methods: {
    updateSearchNumber(val) {
      this.searchResult = val
    }
  }

}
</script>

<style scoped>
.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 2;
    top: 0;
}
</style>