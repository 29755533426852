<template>
  <div>
    <v-card
      v-resize="onResize"
      id="main-card"
      class="pa-0 my-4"
      min-height="275"
      :flat="$vuetify.breakpoint.mdAndDown"
      :outlined="$vuetify.breakpoint.lgAndUp"
      tile
    >
      <v-row class="pa-0">
        <v-col class="py-0" cols="12" sm="5" lg="12">
          <Carousel
            class="carousel-img"
            v-if="Photos.length"
            :Images="Photos"
            :Property="PropertyObject"
            :Type="PropertyType"
          />
          <!-- If no images -->
          <v-card
            :height="noImageHeight"
            flat
            class="d-flex align-center text-center grey lighten-1"
            v-if="!Photos.length"
          >
            <v-card-text class="title font-weight-light white--text">
              <div>
                <v-icon color="white" height="100">mdi-camera-off</v-icon>
              </div>No images available.
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="7" lg="12" class="pt-md-0 pt-lg-5">
          <v-card-subtitle class="py-0 title font-weight-bold">
            {{ Beds }}
            <span v-if="Beds > 1">beds </span>
            <span v-else>bed </span>
            <span v-if="PropertyType === 'letting'">to LET </span>
            <span v-else>for SALE </span>
            @ £{{ Price | priceFilter }}
            <span v-if="PropertyType === 'letting'"> pcm</span>
            <!-- AdvertisedRent for lettings -->
          </v-card-subtitle>

          <v-card-text class="py-0">
            <div id="details" class>
              <div
                v-if="PropertyType === 'letting' && StartDate"
                class="subtitle-2 mb-0"
              >Available {{ StartDate | filterDate }}</div>
              <div
                v-else-if="PropertyType === 'letting' && !StartDate"
                class="subtitle-2 mb-0"
              >Available date n/a</div>
              <div class="font-weight-light">
                <span>{{ AddressNumber }} </span>
                <span v-if="PropertyType === 'letting'">{{AddressFlatRoomNumber}} </span>
                <span>{{ Address1 }}, </span>
                <span v-if="Address2">{{ Address2 }}, </span>
                <!-- <div>{{Address3}}</div> -->
                <span>{{ Postcode }}</span>
              </div>
            </div>

            <div class="description mt-3" id="descriptions">
              <span class="font-weight-light">
                {{
                descriptionText(Description)
                }}
              </span>
              <v-btn
                @click="descriptionDialog = true"
                small
                text
                class="green--text body-2 ml-n1 px-1 font-weight-light text-lowercase"
                v-if="Description.length > 140"
              >...more</v-btn>
            </div>
          </v-card-text>
        </v-col>
        <v-col class="mt-n1 py-0" cols="12">
          <v-card-actions class="pb-md-3 d-flex justify-end">
            <v-btn color="orange" text :disabled="gotGeoLocation" @click="linkToMaps">
              <v-icon left>mdi-google-maps</v-icon>
              <span class="text-capitalize">location</span>
            </v-btn>
            <v-btn color="orange" text @click="openBookAppointment">
              <span class="text-capitalize">book</span>
            </v-btn>
            <!-- <v-btn
              color="orange"
              text
              @click="detailsDialog = true"
            >
            <span class="text-capitalize">details</span>
            </v-btn>-->
          </v-card-actions>
        </v-col>
      </v-row>
    </v-card>

    <!-- Property description dialog -->
    <v-dialog
      v-model="descriptionDialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="font-weight-light">{{ AddressNumber }} {{ Address1 }}, {{ Postcode }}</span>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="descriptionDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="py-5">
          <span
            style="white-space: pre-wrap;"
            class="font-weight-regular subtitle-1"
          >{{ Description }}</span>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Property Details dialog -->
    <!-- <v-dialog
      v-model="detailsDialog"
      scrollable
      :fullscreen="$vuetify.breakpoint.xsOnly"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="primary white--text">
          <span class="font-weight-light">{{AddressNumber}} {{ Address1 }}, {{ Postcode }}</span>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="detailsDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text class="pt-3">OID: {{ PropertyObject }}</v-card-text>
      </v-card>
    </v-dialog>-->

    <!-- Book appointment dialog -->
    <v-dialog
      v-model="bookAppointmentDialog"
      :fullscreen="$vuetify.breakpoint.smAndDown"
      persistent
      :overlay="false"
      transition="dialog-transition"
      min-width="750"
    >
      <BookAppointment
        @closeBookDialog="closeBookDialog"
        :PropertyType="PropertyType"
        :Property="PropertyObject"
      />
    </v-dialog>
  </div>
</template>

<script>
import Carousel from "@/components/Properties/PropertyImageCarousel";
import BookAppointment from "@/components/Properties/BookAppointment.vue";
import moment from 'moment/src/moment'

export default {
  name: 'property-card',
  props: {
    PropertyType: {
      type: String
    },
    PropertyObject: {
      type: Object
    },
    StartDate: {
      type: String
    },
    Price: {
      type: Number
    },
    Beds: {
      type: Number
    },
    Photos: {
      type: Array
    },
    AddressNumber: {
      type: String
    },
    AddressFlatNumber: {
      type: String
    },
    // for letting
    AddressFlatRoomNumber: {
      type: String
    },
    Address1: {
      type: String
    },
    Address2: {
      type: String
    },
    Address3: {
      type: String
    },
    Postcode: {
      type: String
    },
    Description: {
      type: String
    },
    Directions: {
      type: String
    },
    Coordinates: {
      type: Object
    }
  },
  components: {
    Carousel,
    BookAppointment
  },
  data() {
    return {
      noImageHeight: 225,
      descriptionDialog: false,
      detailsDialog: false,
      bookAppointmentDialog: false,
      selectedPropertyID: '',
      selectedPropertySubject: ''
    };
  },
  created() {
    if (this.PropertyType === "letting") { //based on property type it selects the correct OID
      this.selectedPropertyID = this.PropertyObject.Property.OID;
      this.selectedPropertySubject = `${this.PropertyObject.Property.AddressNumber} ${this.PropertyObject.Property.Address1}, ${this.PropertyObject.Property.Postcode}`
    } else {
      this.selectedPropertyID = this.PropertyObject.OID;
      this.selectedPropertySubject = `${this.PropertyObject.AddressNumber} ${this.PropertyObject.Address1}, ${this.PropertyObject.Postcode}`
    }
  },
  methods: {
    openBookAppointment() {
    const propertyObject = {
      OID: this.selectedPropertyID,
      Subject: this.selectedPropertySubject
    }

    //Setting the selected property in the store for use with CreateAppointment
    this.$store.commit('Calendar/SET_PROPERTY_TO_BOOK', propertyObject)
    
    this.bookAppointmentDialog = true;
    },
    closeBookDialog() {
      this.bookAppointmentDialog = false;
      this.$store.commit('Calendar/CLEAR_PROPERTY_TO_BOOK') // cleares saved property object in $store

    },
    descriptionText(val) {
      return val.slice(0, 140);
    },
    onResize() {
      if (window.innerWidth < 600) {
        this.noImageHeight = 275;
      } else if (window.innerWidth > 600) {
        this.noImageHeight = 225;
      } 
    },
    linkToMaps() {
      let url = `https://www.google.com/maps/place/${this.Coordinates.Latitude},${this.Coordinates.Longitude}`;
      window.open(url, "_blank");
    }
  },
  computed: {
    gotGeoLocation() {
      if (this.Coordinates.GeoCalculationDone) {
        return false;
      } else {
        return true;
      }
    }
  },
  filters: {
    filterDate(val) {
      let date = val;
      let today = new Date().toISOString()
      if (date < today) {
        return "now";
      } else {
        return 'from ' + moment(date).format("DD/MM/YYYY");
      }
    },
    priceFilter(item) {
      let newValue = parseFloat(item).toLocaleString("en-GB");
      return newValue;
    }
  }
};
</script>

<style scoped>
.v-card {
  border-bottom: 1px solid rgba(177, 175, 175, 0.685) !important;
}
#descriptions {
  min-height: 75px;
}

.carousel-img {
  cursor: pointer;
}
/* #details {
    min-height: 125px;
  } */
</style>
