<template>
<!-- This component feeds into PropertyInfoDialog with details of the salesInstruction -->
  <div>
    <!-- {{ selectedPropertySalesDetails }} -->
    <div class="intro-text subtitle-1" v-if="selectedPropertySalesDetails">

      <v-card class="my-5" outlined >
        <v-card-title class="font-weight-light">Description</v-card-title>
        <v-card-subtitle>
          <div class="my-1 mb-0 font-weight-bold">
            <span v-if="selectedPropertySalesDetails.Beds">{{selectedPropertySalesDetails.Beds}} </span>
            <span v-if="selectedPropertySalesDetails.Beds > 1">beds </span>
            <span v-else>bed </span>
            <span>FOR SALE </span>
            <span v-if="selectedPropertySalesDetails.GuidePrice">&#64; {{ returnFormattedPrice(selectedPropertySalesDetails.GuidePrice) }} </span>
            <span v-if="selectedPropertySalesDetails.AvailableFrom">available from {{ returnDate(selectedPropertySalesDetails.AvailableFrom) }}</span>
          </div>
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            v-if="selectedPropertySalesDetails.Description" 
            solo flat
            class="font-weight-light"
            :value="selectedPropertySalesDetails.Description"
            rows="5"
            label="Property description"
            color="blue lighten-1"
            readonly
          ></v-textarea> 
        </v-card-text>
      </v-card>
      
      <v-card class="my-5" outlined >
        <v-card-title class="pb-0 font-weight-light">Access</v-card-title>
        <v-card-text>
          <!-- Google maps link -->
          <div class="ma-3 property-directions" v-if="selectedPropertySalesDetails.Coordinates">
            <v-btn color="blue" outlined class="body-1"  @click="linkToMaps(selectedPropertySalesDetails.Coordinates)">
              <v-icon left>mdi-google-maps</v-icon>
              <span class="text-capitalize">Directions</span>
            </v-btn>
            <!-- {{selectedPropertySalesDetails.Coordinates}} -->
          </div>
          <v-textarea
            v-if="selectedPropertySalesDetails.Directions" 
            solo flat
            class="font-weight-light"
            :value="selectedPropertySalesDetails.Directions"
            rows="5"
            label="Directions"
            color="blue lighten-1"
            readonly
          ></v-textarea>
          <v-textarea
            v-if="selectedPropertySalesDetails.SalesNotes.ViewingNotes" 
            solo flat
            class="font-weight-light"
            :value="selectedPropertySalesDetails.SalesNotes.ViewingNotes"
            rows="5"
            label="Viewing notes"
            color="blue lighten-1"
            readonly
          ></v-textarea>
        </v-card-text>
      </v-card>

      <v-card
        outlined
        v-if="selectedPropertySalesDetails.ViewingNotes" 
        class="my-5 viewing-notes"
      >
        <v-card-title class="font-weight-light">Viewing notes</v-card-title>
        <v-textarea 
          solo flat
          class="font-weight-light"
          :value="selectedPropertySalesDetails.ViewingNotes"
          rows="3"
          color="blue lighten-1"
          readonly
        ></v-textarea>
      </v-card>

      <div class="my-5 vendor-notes">
        <v-card outlined>
          <v-card-title class="font-weight-light">{{selectedPropertySalesDetails.LinkedVendor.PersonType}}</v-card-title>
          <v-card-text>
            <span class="title font-weight-light">
              <span>{{selectedPropertySalesDetails.LinkedVendor.Title}} </span>
              <span>{{selectedPropertySalesDetails.LinkedVendor.Forename}} </span>
              <span>{{selectedPropertySalesDetails.LinkedVendor.Surname}}</span>
            </span>
            <div>
              <v-btn 
                v-if="selectedPropertySalesDetails.LinkedVendor.MobilePhone" 
                :href="`tel:${selectedPropertySalesDetails.LinkedVendor.MobilePhone}`"
                class="mb-1 mt-2 font-weight-medium" 
                text outlined 
                color="blue"
              >
                <v-icon left small>mdi-phone</v-icon>{{selectedPropertySalesDetails.LinkedVendor.MobilePhone}}
              </v-btn>
            </div>
            <div>
              <v-btn 
                v-if="selectedPropertySalesDetails.LinkedVendor.EmailAddress" 
                :href="`mailto:${selectedPropertySalesDetails.LinkedVendor.EmailAddress}?body=Dear%20${selectedPropertySalesDetails.LinkedVendor.Forename},`"
                class="my-1 font-weight-medium" 
                text outlined 
                color="blue"
              >
                <v-icon left small>mdi-at</v-icon>{{selectedPropertySalesDetails.LinkedVendor.EmailAddress}}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </div>
    </div>
      <!--/sales instructions  -->
  </div>
</template>

<script>
import moment from 'moment/src/moment'
export default {
  props: ['selectedPropertySalesDetails'],
  methods: {
    linkToMaps(value) {
      // let geoLocation = value.replace(/\s+/g, '+').toLowerCase();
      // let url = `https://www.google.com/maps/place/${geoLocation}`
      let url = `https://www.google.com/maps/place/${value.Latitude},${value.Longitude}`
      window.open(url, '_blank');
    },
    returnDate(date) {
      return moment(date).format("DD/MM/YYYY")
    },
    returnFormattedPrice(price) {
      let newValue = parseFloat(price).toLocaleString('en-GB')
      return '£' + newValue
    },
  }
}
</script>
