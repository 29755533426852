<template>
  <div class="sticky">
    <div class="white py-0 d-flex flex-wrap d-lg-none align-center">
      <v-btn
        @click="showFilters = !showFilters"
        text
        color="blue"
        class="pl-0 font-weight-light text-capitalize"
      >
        <v-icon x-small class="mr-1">mdi-tune</v-icon>
        <span class="text-body-1">filters</span>
      </v-btn>
      <v-btn
        @click="refreshDefault"
        class="font-weight-light text-capitalize"
        text
        color="green"
      >
        <v-icon x-small class="mr-1">mdi-restart</v-icon>
        <span class="text-body-1">clear</span>
      </v-btn>
      <v-spacer></v-spacer>
      <span class="ml-12 d-sm-none font-weight-light"
        >{{ totalProperties }}
        <span v-if="totalProperties > 1 || totalProperties == 0"
          >properties</span
        >
        <span v-else>property</span>
      </span>
      <v-spacer></v-spacer>
    </div>
    <v-expand-transition>
      <v-card class="mb-6 blue lighten-5" v-if="showFilters">
        <v-card-text class="blue--text py-0" v-if="type">
          <v-container>
            <!-- Dialog filters -->
            <v-row align="center">
              <v-col class="my-0 py-0" cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <span class="headline font-weight-light">Beds</span>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      color="blue"
                      v-model="minBeds"
                      :items="minBedNumber"
                      label="Min beds"
                      dense
                      outlined
                      @change="refresh"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      color="blue"
                      v-model="maxBeds"
                      :items="maxBedNumber"
                      label="Max beds"
                      dense
                      outlined
                      @change="refresh"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="my-n5 py-0" cols="12" sm="6">
                <v-row>
                  <v-col cols="12">
                    <span class="headline font-weight-light">Price</span>
                  </v-col>
                  <v-col class="" cols="6" offset="">
                    <v-select
                      color="blue"
                      v-model="minPrice"
                      :items="returnPrices"
                      item-text="displayValue"
                      item-value="value"
                      label="Min price"
                      dense
                      outlined
                      :menu-props="{ auto: false, maxHeight: 500 }"
                      @change="refresh"
                    ></v-select>
                  </v-col>
                  <v-col class="" cols="6" offset="">
                    <v-select
                      color="blue"
                      v-model="maxPrice"
                      :items="returnPrices"
                      item-text="displayValue"
                      item-value="value"
                      label="Max price"
                      dense
                      outlined
                      :menu-props="{ auto: false, maxHeight: 500 }"
                      @change="refresh"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
              <v-col class="my-0 py-0" cols="12" sm="6">
                <v-row>
                  <v-col cols="12" sm="6">
                    <span class="headline font-weight-light"
                      >Property type</span
                    >
                  </v-col>
                  <v-col class="" cols="12" offset="">
                    <v-select
                      color="blue"
                      v-model="propertyTypes"
                      :items="$store.state.propertyTypes"
                      item-text="Name"
                      item-value="Value"
                      label="Property type"
                      multiple
                      dense
                      outlined
                      :menu-props="{ auto: false, maxHeight: 500 }"
                      @change="refresh"
                    ></v-select>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="d-flex">
              <v-btn @click="showFilters = false" class="mr-auto" color="blue">
                <v-icon left color="white">mdi-chevron-up</v-icon>
                <span class="white--text">Close</span>
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-expand-transition>
    <div class="white mt-3 d-lg-none">
      <!-- Property search slot -->
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "mobile-filter-tab",
  props: ["totalProperties", "type"],
  data() {
    return {
      showFilters: false,
      minBeds: "",
      maxBeds: "",
      minPrice: "",
      maxPrice: "",
      propertyTypes: [],
      minBedNumber: ["No min", 1, 2, 3, 4, 5, 6, 7, 8],
      maxBedNumber: ["No max", 1, 2, 3, 4, 5, 6, 7, 8],
    };
  },
  methods: {
    openFilterDialog() {
      this.$emit("openFilterDialog");
    },
    refresh() {
      this.$emit("refreshFilters", {
        // emits new paramaters for filters
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        minBeds: this.minBeds,
        maxBeds: this.maxBeds,
        propertyTypes: this.propertyTypes,
      });
    },
    refreshDefault() {
      this.$emit("refreshDefault");
    },
  },
  computed: {
    returnPrices() {
      if (this.type === "sales") {
        return this.salesPrices;
      }
      return this.lettingPrices;
    },
    lettingPrices() {
      let prices = [];
      for (let i = 100; i <= 500; i += 50) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 600; i <= 1500; i += 100) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 1750; i <= 3000; i += 250) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      return prices;
    },
    salesPrices() {
      let prices = [];
      for (let i = 50000; i <= 300000; i += 10000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 325000; i <= 500000; i += 25000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 500000; i <= 1000000; i += 50000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 1250000; i <= 3000000; i += 250000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 4000000; i <= 5000000; i += 1000000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      for (let i = 4000000; i <= 5000000; i += 1000000) {
        let value = i;
        let newValue = parseFloat(i).toLocaleString("en-GB");
        let obj = {
          value: value,
          displayValue: newValue,
        };
        prices.push(obj);
      }
      return prices;
    },
  },
};
</script>

<style>
</style>

