<template>
  <div class="lettings">
    <!-- Floating btn to navigate to top of page -->
    <v-btn
      color="primary"
      id="floating-button"
      fab
      @click="toTop"
    >
      <v-icon>mdi-chevron-up</v-icon>
    </v-btn>

    <v-container class="pt-0" fluid>
        <!-- Linear loader for fetching and refreshing of data -->
        <v-progress-linear class="mt-1" v-if="loading" indeterminate></v-progress-linear>

        <!-- Sticky filter header for MObile + Tablet. NB: not used in lg and Up -->
        <!-- SLOT component with btns to open filter dialog-->
        <MobileFilterTab
          class="my-1 mobile-filters"
          @refreshFilters="refreshFilters"
          @refreshDefault="refreshDefault"
          :totalProperties="totalTenancies"
          type="lettings"
        >
          <SearchLettingProperties
            @selectedLettingProperty="setSearchedProperty"
          />
        </MobileFilterTab>

        <!-- Filters for desktop -->
        <DesktopFilters
          @refreshDefault="refreshDefault"
          @refreshFilters="refreshFilters"
          type="lettings"
        >
          <SearchLettingProperties
            @selectedLettingProperty="setSearchedProperty"
          />

        </DesktopFilters>
        
        <v-row>
          <v-col class="mt-n5 pt-0" cols="12" md="12" lg="4" v-for="letting in lettingProperties" :key="letting.OID">
            <!-- Reusable card / also useed for sales instructions -->
            <PropertyCard
              class="mt-0"
              PropertyType="letting"
              :PropertyObject="letting"
              :StartDate="letting.StartDate"
              :Price="letting.AdvertisedRent"
              :Beds="letting.Property.Beds"
              :Photos="letting.Property.Photos"
              :AddressNumber="letting.Property.AddressNumber"
              :AddressFlatRoomNumber="letting.Property.AddressFlatRoomNumber"
              :Address1="letting.Property.Address1"
              :Address2="letting.Property.Address2"
              :Address3="letting.Property.Address3"
              :Postcode="letting.Property.Postcode"
              :Description="letting.Property.Description"
              :Directions="letting.Property.Directions"
              :Coordinates="letting.Property.Coordinates"
            />
          </v-col>
        </v-row>
        
        <!-- Intersecting div for infinite loading -->
        <div 
          v-if="lettingProperties.length >= 9"
          class=""             
          v-intersect="onIntersect" 
        ></div>

        <div class="d-flex justify-center mt-8">
          <v-progress-circular
            v-if="isIntersecting && !completedLoading"
            indeterminate
            color="primary"
            class="o"
          ></v-progress-circular>
        </div>

    </v-container>
  </div>
</template>

<script>
// import LettingPropertiesCards from '@/components/Properties/LettingPropertiesCards.vue';
import PropertyCard from '@/components/Properties/PropertyCard.vue';
import MobileFilterTab from '@/components/Properties/Slots/MobileFilterTab.vue';
import DesktopFilters from '@/components/Properties/Slots/DesktopFilters.vue';
import SearchLettingProperties from '@/components/Searches/SearchLettingProperties.vue';

export default {
  name: 'letting-properties',
  components: {
    // LettingPropertiesCards,
    PropertyCard,
    MobileFilterTab,
    DesktopFilters,
    SearchLettingProperties
  },
  data() {
    return {
      loading: false,
      bedNumber: [0,1,2,3,4,5,6,7,8],
      minPrice: null,
      maxPrice: null,
      minBeds: null,
      maxBeds: null,
      propertyTypes: null,
      lettingProperties: '',
      offset: 0,
      count: 9,
      totalTenancies: 1,
      isIntersecting: false, // when user intersects this point
      completedLoading: false, // when all properties available have been loaded
    }
  },
  created() {
    this.fetchTenancies()
  },
  methods: {
    toTop() {
      this.$vuetify.goTo(0)
    },
    onIntersect(entries) {
      console.log('intercepting')
      this.isIntersecting = entries[0].isIntersecting
      if(this.isIntersecting) {
        this.fetchTenancies()
      } 
    },
    fetchTenancies() {
      this.loading = true
      if(this.offset >= this.totalTenancies || this.lettingProperties.length >= this.totalTenancies) { // if full count reached, exit
        this.completedLoading = true
        this.loading = false
        return
      }
      this.$store.dispatch('myTenancies', { // fetching first 9 properties
        minPrice: this.minPrice,
        maxPrice: this.maxPrice,
        minBeds: this.minBeds,
        maxBeds: this.maxBeds,
        propertyTypes: this.propertyTypes,
        offset: this.offset,
        count: this.count
      })
      .then( ({data}) => {
        this.offset += 9
        this.loading = false

        this.totalTenancies = data.Count
        const freshData = data.Data 
        const newArray = [...this.lettingProperties, ...freshData]
        this.lettingProperties = newArray

        this.$emit('searchResult', this.totalTenancies)
      })
    },
    clearFields() { // simply resets all search parameters
      this.minPrice =  null,
      this.maxPrice = null,
      this.minBeds = null,
      this.maxBeds = null,
      this.propertyTypes = null,
      this.offset = 0,
      this.count = 9,
      this.totalTenancies = 1
    },
    refreshFilters(filter) { // new search with filters included
      this.offset = 0,
      this.count = 9,
      this.totalTenancies = 1
      this.lettingProperties = ''
      this.minPrice = filter.minPrice
      this.maxPrice = filter.maxPrice
      this.minBeds = filter.minBeds
      this.maxBeds = filter.maxBeds
      this.propertyTypes = filter.propertyTypes
      this.fetchTenancies()
    },
    refreshDefault() { // reset all filters and re populate properties
      this.lettingProperties = ''
      this.clearFields()
      this.loading = true
      this.fetchTenancies()
    },
    setSearchedProperty(property) { // method called from cloud search component
      const propertyID = property.OID
      this.$store.dispatch('propertyTenanciesLatest', propertyID)
      .then( res => {
        console.log(res.data)
        const newArray = []
        newArray.push(res.data)
        this.lettingProperties = newArray
      })
      // then set property as single property in rendered array
    },
  },
  computed: {
    lettingPrices() {
      let prices = []
      for(let i = 100; i <= 500; i += 50) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 600; i <= 1500; i += 100) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      for(let i = 1750; i <= 3000; i += 250) {
        let value = i
        let newValue = parseFloat(i).toLocaleString('en-GB')
        let obj = {
          value: value,
          displayValue: newValue
        }
        prices.push(obj) 
      } 
      return prices
    },
  },
}
</script>

<style scoped>
#floating-button {
  position: fixed !important;
  top: 80vh;
  right: 20px;
  z-index: 100;
}
/* .lettings {
  position: relative;
} */
#fixed {
  position: fixed;
  z-index: 101;
}
#card-columns {
  margin-top: 110px;
}
.sticky {
    position: -webkit-sticky !important;
    position: sticky !important;
    z-index: 2;
    top: 0;
}
.intesecting-div {
  background: red;
  height: 1000px;
  width: 100%;
}

.mobile-filters {
  z-index: 10000000 !important;
}
</style>